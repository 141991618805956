import { styled } from '@mui/material'
import TableCellMui from '@mui/material/TableCell'
import TableHeadMui from '@mui/material/TableHead'
import TableRowMui from '@mui/material/TableRow'
import TableContainerMui from '@mui/material/TableContainer'

export const TableHeadCell = styled(TableCellMui)({
  fontWeight: 'bold',
  border: 'none',
})

export const TableHead = styled(TableHeadMui)(({ theme }) => ({
  borderTop: '0',
  borderLeft: '0',
  borderRight: '0',
  borderBottom: '3px solid',
  borderColor: theme.palette.secondary.main,
  borderRadius: '0',
}))

export const TableRow = styled(TableRowMui)({
  borderTop: '0',
  borderLeft: '0',
  borderRight: '0',
  borderBottom: '0px solid',
  borderColor: 'black',
  borderRadius: '0',
})
export const TableCell = styled(TableCellMui)({
  borderBottom: 'none',
})

export const TableContainer = styled(TableContainerMui)({
  marginBottom: '0.8rem',
  borderRadius: '1%',
  borderStyle: 'solid',
  borderColor: 'black',
  borderWidth: '0.125rem',
  boxShadow: 'none',
  paddingLeft: '3.125rem',
  paddingRight: '3.125rem',
  paddingTop: '1.5625rem',
  paddingBottom: '1.5625rem',
})

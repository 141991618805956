import { useEffect, useState } from 'react'
import { FetchedGuest, Guest } from 'interfaces'
import { parseIdentity, parseRole, fetchJsonOpts } from 'utils'

const useGuests = () => {
  const [guests, setGuests] = useState<Guest[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const getGuestsInfo = () =>
    fetch('/api/ui/v1/guests/', fetchJsonOpts())
      .then((response) => (response.ok ? response.json() : []))
      .then((persons) => {
        setGuests(
          persons.map(
            (person: FetchedGuest): Guest => ({
              pid: person.pid,
              first: person.first,
              last: person.last,
              email: person.email,
              mobile: person.mobile,
              fnr: parseIdentity(person.fnr),
              national_id_card_number: parseIdentity(
                person.national_id_card_number
              ),
              passport: parseIdentity(person.passport),
              feide_id: person.feide_id,
              active: person.active,
              registered: person.registered,
              verified: person.verified,
              invitation_status: person.invitation_status,
              roles: person.roles.map((role) => parseRole(role)),
            })
          )
        )
        setLoading(false)
      })
      .catch(() => {
        setGuests([])
        setLoading(false)
      })

  const reloadGuests = () => {
    getGuestsInfo()
  }

  useEffect(() => {
    getGuestsInfo()
  }, [])

  return { guests, reloadGuests, loading }
}

export default useGuests

import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { Container, Typography } from '@mui/material'
import { appInst } from 'appConfig'

interface IPage {
  children: React.ReactNode
  header?: string
  pageWidth?: boolean
}

let faviconPath = ''

switch (appInst) {
  case 'uib':
    faviconPath = 'favicons/uib.ico'
    break
  case 'uio':
    faviconPath = 'favicons/uio.svg'
    break
  case 'uit':
    faviconPath = 'favicons/uit.ico'
    break
  case 'ntnu':
    faviconPath = 'favicons/ntnu.ico'
    break
  default:
    faviconPath = 'favicons/uio.svg'
}

export default function Page(props: IPage) {
  const { header, children, pageWidth } = props
  const { i18n, t } = useTranslation()
  const appTitle = t('common:header:applicationTitle')

  return (
    <>
      <Helmet titleTemplate={`%s - ${appTitle}`} defaultTitle={appTitle}>
        <link rel="icon" href={faviconPath} />
        <html lang={i18n.language} />
        <title>{header}</title>
      </Helmet>
      <Container
        maxWidth={pageWidth ? 'lg' : 'md'}
        sx={{ paddingBottom: '2rem', paddingTop: '2rem' }}
      >
        {header ? <Typography variant="h1">{header}</Typography> : <></>}
        {children}
      </Container>
    </>
  )
}

Page.defaultProps = {
  header: null,
  pageWidth: false,
}

import PersonIcon from '@mui/icons-material/Person'
import { Alert, Box, IconButton, styled, Theme } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

interface SponsorGuestButtonsProps {
  yourGuestsActive?: boolean
  registerNewGuestActive?: boolean
}

const StyledIconButton = styled(IconButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: '1.375rem',
})

interface LocationState {
  cancelledInvitationFor: string
}

export default function SponsorGuestButtons(props: SponsorGuestButtonsProps) {
  const { yourGuestsActive, registerNewGuestActive } = props
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()

  const location = useLocation()
  const state = location.state as LocationState
  const cancelledInvitationFor = state?.cancelledInvitationFor || ''

  const goToOverview = () => {
    navigate('/sponsor')
  }

  const goToRegister = () => {
    navigate('/register')
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          marginBottom: '2rem',
          fontSize: '1.375rem',
        }}
      >
        <StyledIconButton
          onClick={goToOverview}
          sx={{
            color: () => (yourGuestsActive ? 'black' : undefined),
            textDecorationLine: () => (yourGuestsActive ? 'underline' : ''),
          }}
        >
          <PersonIcon
            sx={{
              fontSize: '5rem',
              borderRadius: '4rem',
              borderStyle: 'solid',
              borderColor: (theme: Theme) =>
                yourGuestsActive
                  ? theme.palette.secondary.main
                  : theme.greg.deactivatedColor,
              fill: 'white',
              backgroundColor: (theme: Theme) =>
                yourGuestsActive
                  ? theme.palette.secondary.main
                  : theme.greg.deactivatedColor,
            }}
          />
          {t('yourGuests')}
        </StyledIconButton>

        <StyledIconButton
          onClick={goToRegister}
          sx={{
            color: () => (registerNewGuestActive ? 'black' : undefined),
            textDecorationLine: () =>
              registerNewGuestActive ? 'underline' : '',
          }}
        >
          <PersonAddIcon
            sx={{
              fontSize: '5rem',
              borderRadius: '4rem',
              borderStyle: 'solid',
              borderColor: (theme: Theme) =>
                registerNewGuestActive
                  ? theme.palette.secondary.main
                  : theme.greg.deactivatedColor,
              fill: 'white',
              backgroundColor: (theme: Theme) =>
                registerNewGuestActive
                  ? theme.palette.secondary.main
                  : theme.greg.deactivatedColor,
            }}
          />
          {t('registerNewGuest')}
        </StyledIconButton>
      </Box>
      {cancelledInvitationFor && (
        <Alert
          sx={{ fontSize: '1.375rem', marginTop: '1rem', marginBottom: '1rem' }}
          severity="success"
        >
          {t('guestInfo.invitationCancelled', {
            name: cancelledInvitationFor,
          })}
        </Alert>
      )}
    </Box>
  )
}

SponsorGuestButtons.defaultProps = {
  yourGuestsActive: false,
  registerNewGuestActive: false,
}

import React, { useEffect, useState } from 'react'

import { UserContext } from 'contexts'
import { parseISO } from 'date-fns'
import { User } from 'interfaces'

type UserProviderProps = {
  children: React.ReactNode
}

function UserProvider(props: UserProviderProps) {
  const { children } = props
  const [user, setUser] = useState<User>({
    auth: false,
    auth_type: '',
    fetched: false,
    first_name: '',
    last_name: '',
    feide_id: '',
    person_id: '',
    sponsor_id: '',
    email: '',
    mobile_phone: '',
    fnr: '',
    national_id_card_number: '',
    passport: '',
    roles: [],
    consents: [],
    registration_completed_date: null,
  })

  const getUserInfo = async () => {
    try {
      const response = await fetch('/api/ui/v1/userinfo/')
      const data = await response.json()

      if (response.ok) {
        setUser({
          auth: true,
          auth_type: data.auth_type,
          fetched: true,
          first_name: data.first_name,
          last_name: data.last_name,
          feide_id: data.feide_id,
          person_id: data.person_id,
          sponsor_id: data.sponsor_id,
          email: data.email,
          mobile_phone: data.mobile_phone,
          fnr: data.fnr,
          national_id_card_number: data.national_id_card_number,
          passport: data.passport,
          roles: data.roles,
          consents: data.consents,
          registration_completed_date: data.registration_completed_date
            ? parseISO(data.registration_completed_date)
            : null,
        })
      } else {
        setUser({
          auth: false,
          auth_type: '',
          fetched: true,
          first_name: '',
          last_name: '',
          feide_id: '',
          person_id: '',
          sponsor_id: '',
          email: '',
          mobile_phone: '',
          fnr: '',
          national_id_card_number: '',
          passport: '',
          roles: [],
          consents: [],
          registration_completed_date: null,
        })
      }
    } catch (error) {
      setUser({
        auth: false,
        auth_type: '',
        fetched: true,
        first_name: '',
        last_name: '',
        feide_id: '',
        person_id: '',
        sponsor_id: '',
        email: '',
        mobile_phone: '',
        fnr: '',
        national_id_card_number: '',
        passport: '',
        roles: [],
        consents: [],
        registration_completed_date: null,
      })
    }
  }

  const fetchUserInfo = () => {
    if (!user.auth) {
      getUserInfo()
    }
  }

  useEffect(() => {
    if (!user.auth && !user.fetched) {
      getUserInfo()
    }
  }, [])

  const clearUserInfo = () => {
    setUser({
      auth: false,
      auth_type: '',
      fetched: false,
      first_name: '',
      last_name: '',
      feide_id: '',
      person_id: '',
      sponsor_id: '',
      email: '',
      mobile_phone: '',
      fnr: '',
      national_id_card_number: '',
      passport: '',
      roles: [],
      consents: [],
      registration_completed_date: null,
    })
  }

  return (
    <UserContext.Provider
      value={{ user, fetchUserInfo, clearUserInfo, getUserInfo }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider

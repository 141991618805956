/**
 * Lists the different ways the guest can reach the registration page.
 * Either he can choose login by Feide or ID-porten, or he can use the manual registration,
 * which means that he only will have the information the sponsor entered when the invite was created attached to him.
 */
enum AuthenticationMethod {
  Feide,
  Invite,
  IdPorten,
}

export default AuthenticationMethod

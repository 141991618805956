import { FetchedGuest, FetchedRole, Guest } from 'interfaces'
import { useEffect, useState } from 'react'
import { parseRole, parseIdentity } from 'utils'

const useGuest = (pid: string) => {
  const [guestInfo, setGuest] = useState<Guest>({
    pid: '',
    first: '',
    last: '',
    email: '',
    fnr: null,
    national_id_card_number: null,
    passport: null,
    feide_id: null,
    mobile: '',
    active: false,
    registered: false,
    verified: false,
    roles: [],
    invitation_status: '',
  })

  const getPerson = async (id: string) => {
    try {
      const response = await fetch(`/api/ui/v1/person/${id}`)
      const guest: FetchedGuest = await response.json()

      if (response.ok) {
        setGuest({
          pid: guest.pid,
          first: guest.first,
          last: guest.last,
          email: guest.email,
          mobile: guest.mobile,
          fnr: parseIdentity(guest.fnr),
          national_id_card_number: parseIdentity(guest.national_id_card_number),
          passport: parseIdentity(guest.passport),
          feide_id: guest.feide_id,
          active: guest.active,
          registered: guest.registered,
          verified: guest.verified,
          invitation_status: guest.invitation_status,
          roles: guest.roles.map((role: FetchedRole) => parseRole(role)),
        })
      }
    } catch (error) {
      console.error(error)
    }
  }
  const reloadGuestInfo = () => {
    getPerson(pid)
  }
  useEffect(() => {
    getPerson(pid)
  }, [])

  return { guestInfo, reloadGuestInfo }
}

export default useGuest

import { useState, useEffect } from 'react'
import { fetchJsonOpts } from '../../utils'

type RoleTypeData = {
  id: number
  identifier: string
  name_en: string
  name_nb: string
  max_days: number
}

function useRoleTypes(): RoleTypeData[] | undefined {
  const [roleTypes, setRoleTypes] = useState<RoleTypeData[] | undefined>(
    undefined
  )

  const fetchRoleTypes = async () => {
    const response = await fetch(`/api/ui/v1/roletypes/`, fetchJsonOpts())

    if (response.ok) {
      // The response is a JSON-array
      const roleTypesJson = await response.json()
      setRoleTypes(roleTypesJson)
    } else {
      setRoleTypes([])
      console.error(response)
    }
  }

  useEffect(() => {
    fetchRoleTypes()
  }, [])

  return roleTypes
}

export type { RoleTypeData }
export default useRoleTypes

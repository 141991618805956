import { IconButton, Theme, Box } from '@mui/material'
import { Link } from 'react-router-dom'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTranslation } from 'react-i18next'

interface SponsorInfoButtonsProps {
  to: string
  name: string
}

export default function SponsorInfoButtons({
  to,
  name,
}: SponsorInfoButtonsProps) {
  const [t] = useTranslation('common')
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginBottom: '2rem',
        fontSize: '1.375rem',
        alignItems: 'center',
      }}
    >
      <IconButton
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: '1.375rem',
        }}
        component={Link}
        to={to}
      >
        <ArrowBackIcon
          sx={{
            fontSize: '5rem',
          }}
        />
        {t('button.back')}
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textDecorationLine: 'underline',
          fontSize: '1.375rem',
        }}
      >
        <PersonOutlineRoundedIcon
          sx={{
            fontSize: '5rem',
            borderRadius: '4rem',
            borderStyle: 'solid',
            borderColor: (theme: Theme) => theme.palette.secondary.main,
            fill: 'white',
            backgroundColor: (theme: Theme) => theme.palette.secondary.main,
          }}
        />
        {name}
      </Box>
    </Box>
  )
}

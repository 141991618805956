import { forwardRef, Ref, useImperativeHandle } from 'react'
import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@mui/material'
import { ConsentType, useConsentTypes } from 'hooks/useConsentTypes'
import { getLocalized } from 'utils'
import { GuestConsentData } from '../enteredGuestData'
import { GuestRegisterCallableMethods } from '../guestRegisterCallableMethods'

interface GuestConsentProps {
  nextHandler(consentData: GuestConsentData): void
}

const GuestConsentStep = forwardRef(
  (props: GuestConsentProps, ref: Ref<GuestRegisterCallableMethods>) => {
    const { i18n, t } = useTranslation(['common'])
    const { nextHandler } = props
    const consentTypes = useConsentTypes()
    const {
      register,
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<GuestConsentData>({ mode: 'onTouched' })
    useFieldArray({ control, name: 'consents' })

    const submit: SubmitHandler<GuestConsentData> = (data) => {
      // Exclude consents the guest did not accept an answer for
      const submitdata: GuestConsentData = {
        consents: data.consents?.filter((cons) => cons.choice !== null),
      }
      console.log('consent submit', submitdata)
      nextHandler(submitdata)
    }

    const onSubmit = handleSubmit<GuestConsentData>(submit)
    useImperativeHandle(ref, () => ({ doSubmit: () => onSubmit() }))

    console.log('errors', errors)

    function showChoices(
      consentIndex: number,
      consentType: ConsentType,
      name: string,
      description: string
    ) {
      const { choices } = consentType
      const hasError =
        errors && errors.consents && !!errors.consents[consentIndex]

      const header = (
        <>
          <FormLabel
            sx={{
              fontSize: '1.25rem',
              fontWeight: '500',
              color: (theme: Theme) =>
                hasError ? theme.palette.error.main : 'black',
              paddingTop: '1.25rem',
              paddingBottom: '1rem',
            }}
          >
            {name}
          </FormLabel>
          <Typography
            sx={{ fontWeight: 600 }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </>
      )

      const showError = (text: string) => (
        <FormHelperText sx={{ marginLeft: 0 }}>{text}</FormHelperText>
      )

      if (choices.length === 1) {
        return (
          <FormControl component="fieldset" error={hasError}>
            {header}
            <FormGroup>
              <input
                type="hidden"
                value={consentType.identifier}
                {...register(`consents.${consentIndex}.type` as const)}
              />
              {choices.map((choice) => {
                const choiceText = getLocalized(choice, 'text_', i18n.language)
                return (
                  <FormControlLabel
                    key={choice.value}
                    control={<Checkbox />}
                    label={choiceText}
                    value={choice.value}
                    {...register(`consents.${consentIndex}.choice` as const, {
                      required: consentType.mandatory,
                    })}
                  />
                )
              })}{' '}
            </FormGroup>
            {hasError && showError(t('validation.consentRequired'))}
          </FormControl>
        )
      }
      return (
        <FormControl component="fieldset" error={hasError}>
          {header}

          <RadioGroup>
            <input
              type="hidden"
              value={consentType.identifier}
              {...register(`consents.${consentIndex}.type` as const)}
            />
            {choices.map((choice) => {
              const choiceText = getLocalized(choice, 'text_', i18n.language)
              return (
                <FormControlLabel
                  key={choice.value}
                  value={choice.value}
                  control={<Radio />}
                  label={choiceText}
                  {...register(`consents.${consentIndex}.choice` as const, {
                    required: consentType.mandatory,
                  })}
                />
              )
            })}
          </RadioGroup>
          {hasError && showError(t('validation.consentRequired'))}
        </FormControl>
      )
    }

    return (
      <Box>
        <form onSubmit={onSubmit}>
          {consentTypes.map((consentType, consentIndex) => {
            const name =
              getLocalized(consentType, 'name_', i18n.language) ||
              consentType.identifier
            const description =
              getLocalized(consentType, 'description_', i18n.language) || ''
            return (
              <Box
                sx={{
                  borderRadius: '0.25rem',
                  borderStyle: 'solid',
                  borderWidth: '0.0625rem',
                  borderColor: (theme: Theme) => theme.palette.text.primary,
                  padding: '0 1.5rem 1.2rem 1.5rem',
                  marginBottom: '2.5rem',
                }}
              >
                {showChoices(consentIndex, consentType, name, description)}
              </Box>
            )
          })}
        </form>
      </Box>
    )
  }
)

export default GuestConsentStep

import { ThemeOptions } from '@mui/material'

/*
 UiB style guide: https://manual.uib.no/profilmanual/profilelementer/

 The secondary colours are the same as UiO, since these have good contrast
 and we do have to only use UiB-colours as long as the overall look of
 the pages look similar to other UiB web-sites
 */
const uibTheme: ThemeOptions = {
  greg: {
    footerLinkBgColor: '#383838',
    wizardButtonColor: 'black',
  },
  typography: {
    fontFamily: ['Open Sans', 'Roboto'].join(','),
  },
  palette: {
    primary: {
      main: '#cf3c3a',
      light: '#ff6c6a',
    },
    secondary: {
      main: '#01579B',
      dark: '#1565c0',
      light: '#A4C8E4',
    },
  },
}

export default uibTheme

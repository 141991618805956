import { useTranslation } from 'react-i18next'

import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'

import { HrefButton } from 'components/button'
import { Button, Typography } from '@mui/material'

const StyledBox = styled(Box)({
  borderStyle: 'solid',
  borderColor: 'black',
  borderWidth: '0.125rem',
  borderRadius: '0.6875rem',
})

interface LoginBoxProps {
  header: string
  info: string
  manual?: boolean
  inheritWidth?: boolean
  onClickLogin?: () => any
}

const LoginBox = ({
  header,
  info,
  manual,
  inheritWidth,
  onClickLogin,
}: LoginBoxProps) => {
  const { t } = useTranslation('login')
  return (
    <StyledBox
      sx={{
        width: inheritWidth ? 'inherit' : '24rem',
        height: '18rem',
        marginBottom: { xs: '2rem', md: '0rem' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '2rem',
          height: '35%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: 1 }}>
          <AccountBoxOutlinedIcon
            fontSize="inherit"
            sx={{
              marginRight: { xs: '0rem', md: '0.5rem' },
            }}
          />
          <Typography variant="h3" component="h2">
            {header}
          </Typography>
        </Box>
        <Typography variant="body2">{info}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          margin: '2rem',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <HrefButton to="/oidc/authenticate/" onClick={onClickLogin}>
          <Box sx={{ margin: '0 2rem 0 2rem', textTransform: 'none' }}>
            {t('login')}
          </Box>
        </HrefButton>

        {manual && (
          <Button
            sx={{ textTransform: 'none', border: '2px solid' }}
            href="/guestregister/"
            color="secondary"
          >
            <Box sx={{ margin: '0 2rem 0 2rem' }}>{t('manual')}</Box>
          </Button>
        )}
      </Box>
    </StyledBox>
  )
}
LoginBox.defaultProps = { manual: false, inheritWidth: false, onClickLogin: {} }
export default LoginBox

import { Route, Routes } from 'react-router-dom'

import FrontPage from 'routes/sponsor/frontpage'
import useGuests from 'hooks/useGuests'
import GuestRoutes from './guest'

function Sponsor() {
  const { guests, reloadGuests, loading } = useGuests()

  return (
    <Routes>
      <Route
        path="guest/:pid/*"
        element={<GuestRoutes reloadGuests={reloadGuests} />}
      />
      <Route
        path=""
        element={<FrontPage guests={guests} loading={loading} />}
      />
    </Routes>
  )
}

export default Sponsor

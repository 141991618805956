import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { Link } from '@mui/material'

import {
  accessibilityStatementLink,
  appInst,
  responsibleOrganization,
  responsibleOrganizationEn,
  responsibleOrganizationLink,
  responsibleOrganizationLinkEn,
  itRulesLink,
  itRulesLinkEn,
  privacyPolicyLink,
  privacyPolicyLinkEn,
  technicalSupportLink,
  technicalSupportLinkEn,
  documentationLink,
  documentationLinkEn,
} from 'appConfig'

import { getFooterLogo } from './logos'

const FooterWrapper = styled('footer')({
  height: 'fit-content',
  padding: '0',
  marginTop: 'auto',
})

const InfoSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.greg.footerLinkBgColor,
  padding: '2rem 2rem 4rem 2rem',
}))

const FooterHeader = styled('div')({
  fontSize: '1.9375rem',
  color: 'white',
  padding: '1rem 0 2rem 0',
})

const LinkContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

const LinkSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  color: 'white',
  fontSize: '1rem',
})

const LinkHeader = styled('div')({
  fontSize: '0.75rem',
  marginBottom: '0.5rem',
})

const LogoSection = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  fontSize: '1.5rem',
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'space-between',
  ...(appInst === 'uio' && { padding: '1rem 0 1rem 2rem' }),
}))

const StyledLink = styled(Link)({
  color: 'white',
  fontSize: '1.0625rem',
})

StyledLink.defaultProps = {
  underline: 'hover',
}

const LogoContainer = styled('div')({})

function Footer() {
  const { t, i18n } = useTranslation(['common', 'footer'])
  const getLink = (enLink: string | null, noLink: string | null) => {
    let link
    if (i18n.language === 'en') {
      link = enLink || noLink
    } else {
      link = noLink || enLink
    }
    return link || ''
  }

  const getResponsibleOrganization = () => i18n.language === 'en' ? responsibleOrganizationEn : responsibleOrganization    
  
  const getItRulesLink = () => getLink(itRulesLinkEn, itRulesLink)
  const getTechnicalSupportLink = () =>
    getLink(technicalSupportLinkEn, technicalSupportLink)
  const getPrivacyPolicyLink = () =>
    getLink(privacyPolicyLinkEn, privacyPolicyLink)
  const getDocumentationLink = () =>
    getLink(documentationLinkEn, documentationLink)
  const getResponsibleOrganizationLink = () =>
    getLink(responsibleOrganizationLinkEn, responsibleOrganizationLink)
  return (
    <FooterWrapper>
      <InfoSection>
        <FooterHeader>{t('footer:applicationTitle')}</FooterHeader>

        <LinkContainer>
          <LinkSection>
            <LinkHeader>{t('footer:link.terms.header')}</LinkHeader>
            <StyledLink href={getItRulesLink()}>
              {t('footer:link.terms.ITRules')}
            </StyledLink>
            <StyledLink href={getPrivacyPolicyLink()}>
              {t('footer:link.terms.privacy')}
            </StyledLink>
            {accessibilityStatementLink && <StyledLink href={accessibilityStatementLink}>
              {t('footer:link.terms.accessibilityStatement')}
            </StyledLink>}
          </LinkSection>

          <LinkSection>
            <LinkHeader>{t('footer:link.help.header')}</LinkHeader>
            <StyledLink href={getTechnicalSupportLink()}>
              {t('footer:link.help.contact')}
            </StyledLink>
            <StyledLink href={getDocumentationLink()}>
              {t('footer:link.help.doc')}
            </StyledLink>
          </LinkSection>

          <LinkSection>
            <LinkHeader>
              {t('footer:link.responsibleOrganization.header')}
            </LinkHeader>
            <StyledLink href={getResponsibleOrganizationLink()}>
              {getResponsibleOrganization()}
            </StyledLink>
          </LinkSection>
        </LinkContainer>
      </InfoSection>
      <LogoSection>
        <LogoContainer>{getFooterLogo()}</LogoContainer>
      </LogoSection>
    </FooterWrapper>
  )
}

export default Footer

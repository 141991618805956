import { useState, useEffect } from 'react'
import { fetchJsonOpts } from 'utils'

type ConsentChoice = {
    id?: number
    value: string
    text_en: string
    text_nb: string
    text_nn: string
}

type ConsentType = {
  id: number
  identifier: string
  name_en: string
  name_nb: string
  name_nn: string
  description_en: string
  description_nb: string
  description_nn: string
  mandatory: boolean
  user_allowed_to_change: boolean
  valid_from: string
  choices: ConsentChoice[]
}

export function useConsentTypes(): ConsentType[] {
  const [consentTypes, setConsentTypes] = useState<ConsentType[]>([])

  async function fetchConsentTypes() {
    fetch(`/api/ui/v1/consenttypes`, fetchJsonOpts())
      .then((data) => data.text())
      .then((result) => {
        setConsentTypes(JSON.parse(result))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    fetchConsentTypes()
  }, [])

  return consentTypes
}

export type { ConsentType, ConsentChoice }
export default useConsentTypes

import { ThemeOptions } from '@mui/material'

/*
 UiT style guide: https://uit.no/ansatte/grafiskprofil#kapittel_617363
 */
const uitTheme: ThemeOptions = {
  greg: {
    footerLinkBgColor: '#003349',
    wizardButtonColor: 'black',
  },
  typography: {
    fontFamily: 'Open Sans',
  },
  palette: {
    primary: {
      main: '#003349',
      light: '#ffffff',
    },
    secondary: {
      main: '#007396',
      light: '#59bec9',
    },
  },
}

export default uitTheme

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button } from '@mui/material'

import { useNavigate } from 'react-router-dom'

const StepSubmitSuccess = () => {
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()

  return (
    <>
      <Box
        sx={{
          paddingTop: '1rem',
          paddingBottom: '1rem',
          typography: 'h3',
        }}
      >
        {t('thankYou')}
      </Box>

      <Box sx={{ marginTop: '2rem' }}>
        {t('sponsorSubmitSuccessDescription')}
      </Box>

      <Button
        variant="contained"
        color="secondary"
        sx={{
          marginTop: '2rem',
        }}
        onClick={() => {
          navigate('/')
        }}
      >
        {t('button.backToFrontPage')}
      </Button>
    </>
  )
}

export default StepSubmitSuccess

import { useTranslation } from 'react-i18next'
import { appInst } from 'appConfig'
import { Box, useMediaQuery } from '@mui/material'
import { ReactComponent as UiOLogoNo } from './uio/uio-logo-neg-no.svg'
import { ReactComponent as UiOLogoEn } from './uio/uio-logo-neg-en.svg'
import { ReactComponent as UiOLogoSealNo } from './uio/uio-segl-full-neg-no.svg'
import { ReactComponent as UiOLogoSealEn } from './uio/uio-segl-full-neg-en.svg'

import { ReactComponent as UiBLogoNo } from './uib/UiBlogo_hvit_v.svg'
import { ReactComponent as UiBLogoEn } from './uib/UiBlogo_Eng_white_left.svg'
import { ReactComponent as UiBEmblem } from './uib/UiB-emblem_white.svg'

import { ReactComponent as UiTLogoNb } from './uit/uit-logo-nb.svg'
import { ReactComponent as UiTLogoNn } from './uit/uit-logo-nn.svg'
import { ReactComponent as UiTLogoEn } from './uit/uit-logo-en.svg'

import { ReactComponent as NTNULogo } from './ntnu/ntnu-logo-black-no-slogan.svg'

function DynamicUiBLogo() {
  const { i18n } = useTranslation()
  const spaceAvailable = useMediaQuery('(min-width:700px)')

  // If there is little space available do as on the uib.no page and
  // only show the emblem and UiB as tet
  if (!spaceAvailable) {
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <UiBEmblem
          style={{
            minHeight: '5rem',
            minWidth: '5rem',
            maxWidth: '10rem',
            width: '100%',
          }}
        />
        <Box
          sx={{
            fontFamily:
              '"adobe-garamond-pro","Open Sans", "Roboto", "Times New Roman",Times,Georgia,"DejaVu Serif",serif;',
            marginRight: '0.5rem',
          }}
        >
          <h1>UiB</h1>
        </Box>
      </Box>
    )
  }
  if (i18n.language === 'en') {
    return (
      <UiBLogoEn
        style={{
          minHeight: '5rem',
          minWidth: '10rem',
          maxWidth: '30rem',
          width: '100%',
        }}
      />
    )
  }
  return (
    <UiBLogoNo
      style={{
        minHeight: '5rem',
        minWidth: '10rem',
        maxWidth: '30rem',
        width: '100%',
      }}
    />
  )
}

function getHeaderLogo() {
  const { i18n } = useTranslation()
  switch (appInst) {
    case 'uio':
      if (i18n.language === 'en') {
        return (
          <UiOLogoEn
            style={{
              minHeight: '5rem',
              minWidth: '5rem',
              maxWidth: '15rem',
              width: '100%',
            }}
          />
        )
      }
      return (
        <UiOLogoNo
          style={{
            minHeight: '5rem',
            minWidth: '5rem',
            maxWidth: '15rem',
            opacity: '0.99',
            width: '100%',
          }}
        />
      )
    case 'uib':
      return DynamicUiBLogo()
    case 'uit':
      if (i18n.language === 'en') {
        return (
          <UiTLogoEn
            style={{
              width: '100%',
              maxHeight: '2.5rem',
              minWidth: '5rem',
              marginTop: '0.5rem',
              marginBottom: '0.5rem',
            }}
          />
        )
      }
      if (i18n.language === 'nn') {
        return (
          <UiTLogoNn
            style={{
              width: '100%',
              maxHeight: '2.5rem',
              minWidth: '5rem',
              marginTop: '0.5rem',
              marginBottom: '0.5rem',
            }}
          />
        )
      }
      return (
        <UiTLogoNb
          style={{
            width: '100%',
            maxHeight: '2.5rem',
            minWidth: '5rem',
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
          }}
        />
      )
    case 'ntnu':
      return (
        <NTNULogo
          style={{
            width: '100%',
            maxHeight: '2.5rem',
            marginTop: '1.5rem',
            marginBottom: '1.5rem',
          }}
        />
      )
    default:
      return <></>
  }
}

function getFooterLogo() {
  const { i18n } = useTranslation()

  switch (appInst) {
    case 'uio':
      if (i18n.language === 'en') {
        return (
          <UiOLogoSealEn
            style={{
              minHeight: '6rem',
              minWidth: '10rem',
              maxWidth: '20rem',
              width: '100%',
            }}
          />
        )
      }
      return (
        <UiOLogoSealNo
          style={{
            minHeight: '6rem',
            minWidth: '10rem',
            maxWidth: '20rem',
            width: '100%',
          }}
        />
      )
    case 'uib':
      // No footer logo defined for UiB
      return <></>
    default:
      return <></>
  }
}

export { getHeaderLogo, getFooterLogo }

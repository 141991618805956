import React, { Suspense, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'libphonenumber-js'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import { Box, Button, CircularProgress } from '@mui/material'

import { fetchJsonOpts, splitPhoneNumber, submitJsonOpts } from 'utils'
import { guestConsentStepEnabled } from 'appConfig'
import Page from 'components/page'
import WarningBox, { WarningBoxData } from 'components/warningBox'
import OverviewGuestButton from '../../components/overviewGuestButton'
import { GuestRegisterCallableMethods } from './guestRegisterCallableMethods'
import { GuestConsentData, GuestRegisterData } from './enteredGuestData'
import { GuestInviteInformation } from './guestDataForm'
import AuthenticationMethod from './authenticationMethod'
import GuestRegisterStep from './steps/register'
import GuestConsentStep from './steps/consent'
import GuestSuccessStep from './steps/success'
import { FeatureContext } from '../../../contexts'
import ServerErrorReport, {
  ServerErrorReportData,
} from '../../../components/errorReport'

enum Step {
  RegisterStep,
  ConsentStep,
  SuccessStep,
}

type InvitationData = {
  person: {
    first_name: string
    last_name: string
    private_email?: string
    private_mobile?: string
    fnr?: string
    national_id_card_number?: string
    passport?: string
    feide_id?: string
    date_of_birth?: string
    gender?: string
  }
  sponsor: {
    first_name: string
    last_name: string
  }
  role: {
    ou_name_nb: string
    ou_name_en: string
    role_name_nb: string
    role_name_en: string
    start: string
    end: string
    contact_person_unit: string
  }
  meta: {
    session_type: string
  }
}

/*
 * When the guest reaches this page he has already an invite ID set in his session.
 */
export default function GuestRegister() {
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()
  const { displayGenderFieldForGuest } = useContext(FeatureContext)

  const guestRegisterRef = useRef<GuestRegisterCallableMethods>(null)
  const guestConsentRef = useRef<GuestRegisterCallableMethods>(null)
  const [activeStep, setActiveStep] = useState(0)

  const [initialGuestData, setInitialGuestData] =
    useState<GuestInviteInformation | null>(null)
  const [guestRegisterData, setGuestRegisterData] =
    useState<GuestRegisterData | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [guestConsentData, setGuestConsentData] =
    useState<GuestConsentData | null>(null)
  const [fetchInvitationDataError, setFetchInvitationDataError] =
    useState<ServerErrorReportData | null>(null)
  const [submitGuestDataError, setSubmitGuestDataError] =
    useState<ServerErrorReportData | null>(null)
  const [warningBoxData, setWarningBoxData] = useState<WarningBoxData | null>(
    null
  )

  const fetchInvitationData = async () => {
    const response = await fetch('/api/ui/v1/invited/', fetchJsonOpts())
    if (!response.ok) {
      try {
        // Expect that the error will contain some JSON-data in the body
        const errorData = await response.json()
        setFetchInvitationDataError({
          errorHeading: t('error.invitationDataFetchFailed'),
          statusCode: response.status,
          statusText: response.statusText,
          errorBodyText: t(`error.codes.${errorData?.code}`),
        })
      } catch (e: unknown) {
        console.error(e)

        // Probably some unknown data in the body, create an error message
        // using the rest of the information in the response
        setFetchInvitationDataError({
          errorHeading: t('error.invitationDataFetchFailed'),
          statusCode: response.status,
          statusText: response.statusText,
          errorBodyText: undefined,
        })
      }

      return
    }
    setFetchInvitationDataError(null)
    const data: InvitationData = await response.json()

    let authenticationMethod = AuthenticationMethod.Invite
    switch (data.meta.session_type) {
      case 'feide':
        authenticationMethod = AuthenticationMethod.Feide
        break

      case 'idporten':
        authenticationMethod = AuthenticationMethod.IdPorten
        break

      default:
      // Already set to invite
    }

    const [countryCode, nationalNumber] = data.person.private_mobile
      ? splitPhoneNumber(data.person.private_mobile)
      : ['', '']

    let extractedCountryCode = ''
    if (countryCode) {
      const matchingCountries = getCountries().find(
        (value) => getCountryCallingCode(value) === countryCode
      )

      if (matchingCountries && matchingCountries.length > 0) {
        extractedCountryCode = matchingCountries.toString()
      }
    }

    let passportNumber = ''
    let passportNationality = ''
    if (data.person.passport) {
      const parts = data.person.passport.split('-', 2)
      if (parts.length === 2) {
        ;[passportNationality, passportNumber] = parts
      }
    }

    let dateOfBirth = null
    if (data.person.date_of_birth) {
      dateOfBirth = parse(data.person.date_of_birth, 'yyyy-MM-dd', new Date())
    }

    setInitialGuestData({
      first_name: data.person.first_name ?? '',
      last_name: data.person.last_name ?? '',
      date_of_birth: dateOfBirth,
      gender: data.person.gender ?? '',
      email: data.person.private_email ?? '',
      feide_id: data.person.feide_id ?? '',
      fnr: data.person.fnr ?? '',
      national_id_card_number: data.person.national_id_card_number ?? '',
      passport: passportNumber ?? '',
      passportNationality: passportNationality ?? '',

      mobile_phone_country_code: countryCode ?? '',
      mobile_phone: nationalNumber ?? '',
      countryForCallingCode: extractedCountryCode ?? '',

      ou_name_en: data.role.ou_name_en ?? '',
      ou_name_nb: data.role.ou_name_nb ?? '',
      role_name_en: data.role.role_name_en ?? '',
      role_name_nb: data.role.role_name_nb ?? '',
      role_start: data.role.start ?? '',
      role_end: data.role.end ?? '',
      contact_person_unit: data.role.contact_person_unit ?? '',

      authentication_method: authenticationMethod,
    })
  }

  useEffect(() => {
    fetchInvitationData()
  }, [])

  useEffect(() => {
    console.log('index useEffect initialGuestData', initialGuestData)
    if (!initialGuestData) {
      return
    }
    setGuestRegisterData({
      firstName: initialGuestData.first_name,
      lastName: initialGuestData.last_name,
      dateOfBirth: initialGuestData.date_of_birth ?? null,
      mobilePhone: initialGuestData.mobile_phone ?? '',
      mobilePhoneCountry: initialGuestData.countryForCallingCode ?? '',
      nationalIdNumber: initialGuestData.fnr ?? '',
      nationalIdCardNumber: initialGuestData.national_id_card_number ?? '',
      passportNumber: initialGuestData.passport ?? '',
      passportNationality: initialGuestData.passportNationality ?? '',
      gender: initialGuestData.gender ?? '',
    })
  }, [initialGuestData])

  const handleNext = () => {
    if (activeStep === Step.RegisterStep) {
      if (guestRegisterRef.current) {
        guestRegisterRef.current.doSubmit()
      }
    }
    if (activeStep === Step.ConsentStep) {
      if (guestConsentRef.current) {
        guestConsentRef.current.doSubmit()
      }
    }
  }

  const handleBack = () => {
    if (activeStep === Step.ConsentStep) {
      setActiveStep(Step.RegisterStep)
      // Clear error if any
      setSubmitGuestDataError(null)
      setWarningBoxData(null)
    }
  }

  const makePayload = (
    registerData: GuestRegisterData,
    consentData: GuestConsentData
  ): any => {
    const payload: any = {
      person: {},
    }
    const parsedPhone = parsePhoneNumber(
      registerData.mobilePhone,
      registerData.mobilePhoneCountry as CountryCode
    )
    payload.person.private_mobile = parsedPhone.format('E.164')

    if (initialGuestData !== null) {
      // Only include the fields if they have actually changed. Could also send them back to the
      // server even if there is no change, since the server checks for illegal updates, but looks
      // a bit nicer if unnecessary fields have been removed from the payload
      if (registerData.firstName !== initialGuestData.first_name) {
        payload.person.first_name = registerData.firstName
      }

      if (registerData.lastName !== initialGuestData.last_name) {
        payload.person.last_name = registerData.lastName
      }

      if (registerData.nationalIdNumber !== initialGuestData.fnr) {
        payload.person.fnr = registerData.nationalIdNumber
      }

      if (
        registerData.nationalIdCardNumber !==
        initialGuestData.national_id_card_number
      ) {
        payload.person.national_id_card_number =
          registerData.nationalIdCardNumber
      }
    }

    if (registerData.passportNumber && registerData.passportNationality) {
      payload.person.passport = `${registerData.passportNationality}-${registerData.passportNumber}`
    }

    if (registerData.dateOfBirth) {
      payload.person.date_of_birth = format(
        registerData.dateOfBirth as Date,
        'yyyy-MM-dd'
      )
    }

    if (consentData.consents) {
      payload.person.consents = consentData.consents
    }

    // Do not expect gender to be set if the field should not be shown
    if (displayGenderFieldForGuest && registerData.gender) {
      payload.person.gender = registerData.gender
    }

    return payload
  }

  const submitPayload = (
    registerData: GuestRegisterData,
    consentData: GuestConsentData | null
  ) => {
    if (!registerData) {
      setActiveStep(Step.RegisterStep)
      return
    }
    if (guestConsentStepEnabled && !consentData) {
      setActiveStep(Step.ConsentStep)
      return
    }
    const consents = guestConsentStepEnabled
      ? consentData ?? { consents: [] }
      : { consents: [] }
    const payload: any = makePayload(registerData, consents)
    console.log('submitting payload', payload)
    fetch('/api/ui/v1/invited/', submitJsonOpts('POST', payload))
      .then((response) => {
        if (response.ok) {
          setActiveStep(Step.SuccessStep)
          setSubmitGuestDataError(null)
          setWarningBoxData(null)
        } else {
          // Expect that the error will contain JSON-data in the body
          // and that there will be a code field there
          response
            .json()
            .then(() => {
              setWarningBoxData({
                heading: t('error.existingUserErrorHeader'),
                bodyText: t(`error.codes.duplicate_identity_info`),
              })
            })
            .catch((error) => {
              console.error(error)

              // Probably some unknown data in the body, create an error message
              // using the rest of the information in the response
              setSubmitGuestDataError({
                errorHeading: t('error.guestRegistrationFailed'),
                statusCode: response.status,
                statusText: response.statusText,
                errorBodyText: undefined,
              })
            })
        }
      })
      .catch((error) => {
        // Something went wrong before/during the backend was called
        setSubmitGuestDataError({
          errorHeading: t('error.guestRegistrationFailed'),
          statusCode: undefined,
          statusText: undefined,
          errorBodyText: undefined,
        })
        console.error(error)
      })
  }

  const handleForwardFromRegister = (registerData: GuestRegisterData): void => {
    console.log('handleForwardFromRegister')
    setGuestRegisterData(registerData)
    if (!guestConsentStepEnabled) {
      submitPayload(registerData, null)
      return
    }
    setActiveStep(Step.ConsentStep)
  }

  const handleForwardFromConsent = (consentData: GuestConsentData): void => {
    console.log('handleForwardFromConsent consentData is', consentData)
    setGuestConsentData(consentData)
    if (!guestRegisterData) {
      setActiveStep(Step.RegisterStep)
      return
    }
    if (!consentData) {
      setActiveStep(Step.ConsentStep)
      return
    }
    submitPayload(guestRegisterData, consentData)
  }

  const handleCancel = () => {
    // Go back to the invite page. The invitation ID does not need
    // to be specified here, since the user has already been
    // logged in and can be identified without the invitation ID
    navigate('/complete-registration')
  }

  return (
    <Page>
      <OverviewGuestButton />
      {/* Step: Registration  */}
      {activeStep === Step.RegisterStep &&
        guestRegisterData &&
        initialGuestData && (
          <Box sx={{ width: '100%' }}>
            <GuestRegisterStep
              nextHandler={handleForwardFromRegister}
              initialGuestData={initialGuestData}
              registerData={guestRegisterData}
              ref={guestRegisterRef}
            />
          </Box>
        )}

      {/* Step: Consent */}
      {activeStep === Step.ConsentStep && (
        <Box sx={{ width: '100%' }}>
          <Suspense fallback={<CircularProgress />}>
            <GuestConsentStep
              nextHandler={handleForwardFromConsent}
              ref={guestConsentRef}
            />
          </Suspense>
        </Box>
      )}

      {/* Step: Success */}
      {activeStep === Step.SuccessStep && <GuestSuccessStep />}

      {/* Navigation */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          color: 'primary.main',
          paddingBottom: '1rem',
        }}
      >
        {activeStep === Step.RegisterStep && (
          <Button
            data-testid="button-next"
            color="secondary"
            sx={{ mr: 1 }}
            onClick={handleNext}
          >
            {t('button.next')}
          </Button>
        )}
        {activeStep === Step.ConsentStep && (
          <Button onClick={handleNext} sx={{ mr: 1 }}>
            {t('button.finishRegistration')}
          </Button>
        )}
        {activeStep === Step.ConsentStep && (
          <Button
            data-testid="button-back"
            color="secondary"
            sx={{ mr: 1 }}
            onClick={handleBack}
          >
            {t('button.back')}
          </Button>
        )}

        {activeStep !== Step.SuccessStep && (
          <Button color="secondary" onClick={handleCancel}>
            {t('button.cancel')}
          </Button>
        )}
      </Box>

      {fetchInvitationDataError !== null && (
        <ServerErrorReport
          errorHeading={fetchInvitationDataError?.errorHeading}
          statusCode={fetchInvitationDataError?.statusCode}
          statusText={fetchInvitationDataError?.statusText}
          errorBodyText={fetchInvitationDataError?.errorBodyText}
        />
      )}

      {submitGuestDataError !== null && (
        <ServerErrorReport
          errorHeading={submitGuestDataError?.errorHeading}
          statusCode={submitGuestDataError?.statusCode}
          statusText={submitGuestDataError?.statusText}
          errorBodyText={submitGuestDataError?.errorBodyText}
        />
      )}
      {warningBoxData !== null && (
        <WarningBox
          heading={warningBoxData.heading}
          bodyText={warningBoxData.bodyText}
        />
      )}
    </Page>
  )
}

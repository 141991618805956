import { ThemeOptions } from '@mui/material'

// NTNU style guide: https://i.ntnu.no/logo-og-maler

const ntnuTheme: ThemeOptions = {
  greg: {
    footerLinkBgColor: '#272834',
    wizardButtonColor: 'black',
  },
  typography: {
    fontFamily: 'Open Sans',
  },
  palette: {
    primary: {
      main: '#00509e',
      light: '#ffffff',
    },
    secondary: {
      main: '#00509e',
      dark: '#6096d0',
      light: '#9db7e1',
    },
  },
}

export default ntnuTheme

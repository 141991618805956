import { Alert, AlertTitle } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import React from 'react'

export type ServerErrorReportData = {
  errorHeading: string
  statusCode?: number
  statusText?: string
  errorBodyText?: string
}

export default function ServerErrorReport({
  errorHeading,
  statusCode,
  statusText,
  errorBodyText,
}: ServerErrorReportData) {
  const { t } = useTranslation(['common'])
  return (
    <Alert severity="error">
      <AlertTitle>{errorHeading}</AlertTitle>
      {statusCode !== undefined && (
        <Trans i18nKey="error.errorStatusCode" t={t}>
          Status code: {{ statusCode }} (<strong>{{ statusText }}</strong>)
        </Trans>
      )}
      <br />
      {errorBodyText !== undefined && (
        <Trans i18nKey="error.genericServerErrorBody" t={t}>
          Message:
          <blockquote>{{ errorBodyText }}</blockquote>
        </Trans>
      )}
      {t('error.contactHelp')}
    </Alert>
  )
}

ServerErrorReport.defaultProps = {
  statusCode: undefined,
  statusText: undefined,
  errorBodyText: undefined,
}

import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'

import ServerErrorReport, {
  ServerErrorReportData,
} from 'components/errorReport'
import IdentityLine from 'components/identityLine'
import Page from 'components/page'
import RoleLine from 'components/roleLine'
import {
  TableCell,
  TableContainer,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'components/table'
import { enableNationalIdCardNumber } from 'appConfig'
import { useUserContext } from 'contexts'
import { Guest } from 'interfaces'
import SponsorInfoButtons from 'routes/components/sponsorInfoButtons'
import { isValidEmail, isValidMobilePhoneNumber, submitJsonOpts } from 'utils'

type GuestInfoParams = {
  pid: string
}

type GuestInfoProps = {
  guest: Guest
  updateEmail: (validEmail: string, errorCallback: (error: any) => void) => void
  updateMobilePhone: (
    validMobilePhone: string,
    errorCallback: (error: any) => void
  ) => void
  resend: () => void
  reloadGuests: () => void
  reloadGuest: () => void
}

type CancelConfirmationDialogProps = {
  open: boolean
  onClose: (ok: boolean) => void
}

function CancelConfirmationDialog({
  open,
  onClose,
}: CancelConfirmationDialogProps) {
  const [t] = useTranslation(['common'])

  const handleCancel = () => {
    onClose(false)
  }

  const handleOk = () => {
    onClose(true)
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{t('confirmationDialog.cancelInvitation')}</DialogTitle>

      <DialogContent>
        {t('confirmationDialog.cancelInvitationDescription')}
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="secondary" onClick={handleCancel}>
          {t('button.cancel')}
        </Button>
        <Button autoFocus color="secondary" onClick={handleOk}>
          {t('button.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type Email = {
  email: string
}

type MobilePhone = {
  mobilePhone: string
}

interface LocationState {
  cancelledRoleName: string
}

export default function GuestInfo({
  guest,
  updateEmail,
  updateMobilePhone,
  resend,
  reloadGuests,
  reloadGuest,
}: GuestInfoProps) {
  const { pid } = useParams<GuestInfoParams>()
  const [t] = useTranslation(['common'])
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as LocationState
  const cancelledRoleName = state?.cancelledRoleName || ''
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false)
  const [showEmailSent, setShowEmailSent] = useState<Boolean>(false)
  const [emailUpdateError, setEmailUpdateError] =
    useState<ServerErrorReportData>()
  const [mobilePhoneUpdateError, setMobilePhoneUpdateError] =
    useState<ServerErrorReportData>()
  const [updateOKMsg, setUpdateOKMsg] = useState<string>('')
  const [showErrorMessage, setShowErrorMessage] = useState<Boolean>(false)
  const { user } = useUserContext()
  const sponsorFeideId = user.feide_id
  const isSponsorForGuest = guest.roles
    .map(({ sponsor_feide_id }) => sponsor_feide_id)
    .includes(sponsorFeideId)

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<Email>({
    mode: 'onTouched',
    defaultValues: { email: guest.email ?? '' },
  })

  const {
    control: controlMobilePhone,
    handleSubmit: handleSubmitMobilePhone,
    setValue: setValueMobilePhone,
    reset: resetMobilePhone,
    formState: {
      errors: errorsMobilePhone,
      isValid: isValidMobilePhone,
      isDirty: isDirtyMobilePhone,
    },
  } = useForm<MobilePhone>({
    mode: 'onTouched',
    defaultValues: { mobilePhone: guest.mobile ?? '' },
  })

  // This function inspects the errorText and tries to
  // create an error report based on it by looking for
  // the code field, if that does not work a generic
  // error report is shown with the raw error text
  const createErrorMessageBasedOnText = (
    errorField: string,
    errorText: string,
    statusCode: number | undefined = undefined,
    statusText: string | undefined = undefined
  ) => {
    let setError = setEmailUpdateError
    let errorHeading = t('error.emailUpdateFailed')
    if (errorField === 'mobilePhone') {
      setError = setMobilePhoneUpdateError
      errorHeading = t('error.mobilePhoneUpdateFailed')
    }
    try {
      // Try to extract code from error text
      const errorAsJson = JSON.parse(errorText)
      if ('code' in errorAsJson) {
        setError({
          errorHeading,
          statusCode,
          statusText,
          errorBodyText: t(`error.codes.${errorAsJson.code}`),
        })
      }
    } catch (e) {
      // Use the text from the error directly if no code could be extracted
      setError({
        errorHeading,
        statusCode: undefined,
        statusText: undefined,
        errorBodyText: errorText,
      })
    }
  }

  const submit: SubmitHandler<Email> = (data) => {
    setEmailUpdateError(undefined)
    updateEmail(data.email, (errorResponse: any) => {
      if (typeof errorResponse === 'string') {
        createErrorMessageBasedOnText('email', errorResponse)
      } else if (errorResponse instanceof Response) {
        // Some not successful response code
        errorResponse
          .text()
          .then((text: string) => {
            createErrorMessageBasedOnText(
              'email',
              text,
              errorResponse.status,
              errorResponse.statusText
            )
          })
          .catch((error: any) => {
            // Not expected
            console.error(error)
          })
      } else {
        // Not a string in the error response, not expected
        setEmailUpdateError({
          errorHeading: t('error.emailUpdateFailed'),
          statusCode: undefined,
          statusText: undefined,
          errorBodyText: errorResponse,
        })
      }
    })

    if (!emailUpdateError) {
      setUpdateOKMsg('update.email')
    }
    // Reload guests to update status on sponsor frontpage
    // in case the email was invalid before update
    reloadGuests()
  }

  const submitMobilePhone: SubmitHandler<MobilePhone> = (data) => {
    setMobilePhoneUpdateError(undefined)
    updateMobilePhone(data.mobilePhone, (errorResponse: any) => {
      if (typeof errorResponse === 'string') {
        createErrorMessageBasedOnText('mobilePhone', errorResponse)
      } else if (errorResponse instanceof Response) {
        // Some not successful response code
        errorResponse
          .text()
          .then((text: string) => {
            createErrorMessageBasedOnText(
              'mobilePhone',
              text,
              errorResponse.status,
              errorResponse.statusText
            )
          })
          .catch((error: any) => {
            // Not expected
            console.error(error)
          })
      } else {
        // Not a string in the error response, not expected
        setMobilePhoneUpdateError({
          errorHeading: t('error.mobilePhoneUpdateFailed'),
          statusCode: undefined,
          statusText: undefined,
          errorBodyText: errorResponse,
        })
      }
    })

    if (!mobilePhoneUpdateError) {
      setUpdateOKMsg('update.mobilePhone')
    }

    reloadGuests()
  }

  const handleCancel = () => {
    setConfirmCancelDialogOpen(true)
  }

  const cancelInvitation = () => {
    // There is no body for this request, but using submitJsonOpts still to
    // set the CSRF-token
    fetch(
      `/api/ui/v1/invite/?person_id=${guest.pid}`,
      submitJsonOpts('DELETE', {})
    )
      .then((res) => {
        if (!res.ok) {
          return null
        }
        return res.text()
      })
      .then((result) => {
        if (result !== null) {
          // The invite for the guest has been cancelled, send the user back to the sponsor front page
          reloadGuests()
          navigate('/sponsor', {
            state: { cancelledInvitationFor: `${guest.first} ${guest.last}` },
          })
        }
      })
      .catch((error) => {
        setConfirmCancelDialogOpen(false)
        setShowErrorMessage(true)
        console.log('error', error)
      })
  }

  const handleDialogClose = (ok: boolean) => {
    setConfirmCancelDialogOpen(false)
    if (ok) {
      cancelInvitation()
    }
  }

  const handleClick = () => {
    resend()
    setShowEmailSent(true)
  }

  useEffect(() => {
    reset({ email: guest.email }) // Forces defaultValue to be set to the new value, making the form not dirty
    setValue('email', guest.email)
    resetMobilePhone({ mobilePhone: guest.mobile })
    setValueMobilePhone('mobilePhone', guest.mobile)
  }, [guest])

  return (
    <Page>
      <SponsorInfoButtons to="/sponsor" name={`${guest.first} ${guest.last}`} />
      <Box sx={{ marginBottom: '2rem' }}>
        <Typography sx={{ marginBottom: '1rem' }} variant="h2">
          {t('guestInfo.contactInfo')}
        </Typography>
        <Typography sx={{ marginBottom: '1rem' }} variant="body1">
          {t('guestInfo.contactInfoBody')}
        </Typography>
        {emailUpdateError !== undefined && (
          <Box sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <ServerErrorReport
              errorHeading={emailUpdateError?.errorHeading}
              statusCode={emailUpdateError?.statusCode}
              statusText={emailUpdateError?.statusText}
              errorBodyText={emailUpdateError?.errorBodyText}
            />
          </Box>
        )}
        {mobilePhoneUpdateError !== undefined && (
          <Box sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <ServerErrorReport
              errorHeading={mobilePhoneUpdateError?.errorHeading}
              statusCode={mobilePhoneUpdateError?.statusCode}
              statusText={mobilePhoneUpdateError?.statusText}
              errorBodyText={mobilePhoneUpdateError?.errorBodyText}
            />
          </Box>
        )}
        {updateOKMsg !== '' && (
          <Alert
            severity="success"
            sx={{ marginBottom: '1rem' }}
            onClose={() => setUpdateOKMsg('')}
          >
            {t(updateOKMsg)}
          </Alert>
        )}
        {showErrorMessage && (
          <Alert severity="error" sx={{ marginBottom: '1rem' }}>
            {t('error.invitationCancelFailed')}
          </Alert>
        )}
        {showEmailSent && (
          <Alert
            severity="success"
            sx={{ fontSize: '1rem', marginBottom: '1rem' }}
          >
            {t('guestInfo.newEmailSent')}
          </Alert>
        )}
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableHeadCell align="left" colSpan={2}>
                  {t('guestInfo.contactInfoTableText')}
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">{t('input.fullName')}</TableCell>
                <TableCell align="left">
                  {`${guest.first} ${guest.last}`}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell align="left">{t('input.email')}</TableCell>
                <TableCell align="left">
                  <form onSubmit={handleSubmit(submit)}>
                    <Box>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          validate: (value) => isValidEmail(value),
                        }}
                        render={({ field: { onBlur, onChange, value } }) => (
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id="email"
                            label={t('input.email')}
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            error={!!errors.email}
                            helperText={errors?.email?.message}
                          />
                        )}
                      />
                      <Box
                        sx={{
                          display: 'block',
                          marginTop: '0.5rem',
                        }}
                      >
                        {/* If the guest has not completed the registration process, he should have an invitation he has not responded to */}
                        {!guest.registered && (
                          <Button color="secondary" onClick={handleClick}>
                            {t('button.resendInvitation')}
                          </Button>
                        )}
                        {!guest.registered && (
                          <Button
                            color="secondary"
                            sx={{
                              marginLeft: '0.5rem',
                            }}
                            onClick={handleCancel}
                          >
                            {t('button.cancelInvitation')}
                          </Button>
                        )}
                        <Button
                          type="submit"
                          color="secondary"
                          sx={{
                            marginLeft: guest.registered ? '0' : '0.5rem',
                          }}
                          disabled={!isValid || !isDirty}
                        >
                          {t('button.save')}
                        </Button>
                      </Box>
                    </Box>
                  </form>
                  <CancelConfirmationDialog
                    open={confirmCancelDialogOpen}
                    onClose={handleDialogClose}
                  />
                </TableCell>
                <TableCell />
              </TableRow>
              {guest.registered && (
                <TableRow>
                  <TableCell align="left">{t('input.mobilePhone')}</TableCell>
                  <TableCell align="left">
                    <form onSubmit={handleSubmitMobilePhone(submitMobilePhone)}>
                      <Controller
                        name="mobilePhone"
                        control={controlMobilePhone}
                        rules={{
                          validate: (value) => isValidMobilePhoneNumber(value),
                        }}
                        render={({ field: { onBlur, onChange, value } }) => (
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id="mobilePhone"
                            label={t('input.mobilePhone')}
                            value={value}
                            disabled={!guest.registered}
                            onBlur={onBlur}
                            onChange={onChange}
                            error={!!errorsMobilePhone.mobilePhone}
                            helperText={errorsMobilePhone?.mobilePhone?.message}
                          />
                        )}
                      />
                      <Box>
                        <Button
                          type="submit"
                          color="secondary"
                          sx={{
                            marginTop: '0.5rem',
                          }}
                          disabled={!isValidMobilePhone || !isDirtyMobilePhone}
                        >
                          {t('button.save')}
                        </Button>
                      </Box>
                    </form>
                  </TableCell>
                </TableRow>
              )}
              {guest.feide_id && (
                <TableRow>
                  <TableCell align="left">{t('feideId')}</TableCell>
                  <TableCell align="left">{guest.feide_id}</TableCell>
                </TableRow>
              )}
              <IdentityLine
                text={t('input.nationalIdNumber')}
                identity={guest.fnr}
                isSponsorForGuest={isSponsorForGuest}
                reloadGuest={reloadGuest}
                reloadGuests={reloadGuests}
              />
              <IdentityLine
                text={t('input.passportNumber')}
                identity={guest.passport}
                isSponsorForGuest={isSponsorForGuest}
                reloadGuest={reloadGuest}
                reloadGuests={reloadGuests}
              />
              {enableNationalIdCardNumber && (
                <IdentityLine
                  text={t('input.nationalIdCardNumber')}
                  identity={guest.national_id_card_number}
                  isSponsorForGuest={isSponsorForGuest}
                  reloadGuest={reloadGuest}
                  reloadGuests={reloadGuests}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Button type="submit" color="secondary" disabled={!isValid || !isDirty}>
          {t('button.save')}
        </Button>
      </Box>
      <Typography sx={{ marginBottom: '1rem' }} variant="h2">
        {t('guestInfo.roleInfoHead')}
      </Typography>
      <Typography sx={{ marginBottom: '1rem' }} variant="body1">
        {t('guestInfo.roleInfoBody')}
      </Typography>
      {cancelledRoleName && (
        <Alert
          sx={{ fontSize: '1rem', marginTop: '1rem', marginBottom: '1rem' }}
          severity="success"
        >
          {t('endRoleDialog.endRole', { role: cancelledRoleName })}
        </Alert>
      )}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableHeadCell align="left">
                {t('guestInfo.roleInfoTableText')}
              </TableHeadCell>
              <TableHeadCell align="left" />
              <TableHeadCell align="left" />
              <TableHeadCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableHeadCell align="left">{t('common:role')}</TableHeadCell>
              <TableHeadCell align="left">{t('common:ou')}</TableHeadCell>
              <TableHeadCell align="left">{t('common:period')}</TableHeadCell>
              <TableHeadCell align="left">{t('common:host')}</TableHeadCell>
              <TableHeadCell align="left" />
            </TableRow>
            {guest.roles.map((role) => {
              if (pid === undefined) {
                return <></>
              }
              return <RoleLine key={role.id} pid={pid} role={role} />
            })}
          </TableBody>
        </Table>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={`/sponsor/guest/${pid}/newrole`}
        >
          {t('sponsor.addRole')}
        </Button>
      </TableContainer>
    </Page>
  )
}

import {
  Box,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { DatePicker } from '@mui/lab'
import React, {
  forwardRef,
  Ref,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { addDays } from 'date-fns/fp'
import { useTranslation } from 'react-i18next'
import useOus, { enSort, nbSort, OuData } from 'hooks/useOus'
import useRoleTypes, { RoleTypeData } from 'hooks/useRoleTypes'
import Autocomplete from '@mui/material/Autocomplete'
import Loading from 'components/loading'
import { isValidEmail, isValidFirstName, isValidLastName } from 'utils'
import { FeatureContext } from 'contexts'
import { RegisterFormData } from './formData'
import { PersonFormMethods } from './personFormMethods'

interface StepPersonFormProperties {
  nextHandler(formState: RegisterFormData): void

  formData: RegisterFormData
}

const StepPersonForm = forwardRef(
  (props: StepPersonFormProperties, ref: Ref<PersonFormMethods>) => {
    const { i18n, t } = useTranslation(['common'])
    const { nextHandler, formData } = props
    const { ous, loading } = useOus()

    const [ouChoice, setOuChoice] = useState(
      formData.ou_id ? formData.ou_id : ''
    )
    const [selectedRoleType, setSelectedRoleType] = useState(
      formData.role_type ? formData.role_type : ''
    )
    const today = new Date()
    const [todayPlusMaxDays, setTodayPlusMaxDays] = useState(today)
    const roleTypes = useRoleTypes()
    const { displayContactAtUnit, displayComment } = useContext(FeatureContext)

    const roleTypeSort = () => (a: RoleTypeData, b: RoleTypeData) => {
      if (i18n.language === 'en') {
        return a.name_nb.localeCompare(b.name_nb)
      }
      return a.name_en.localeCompare(b.name_en)
    }

    const submit: SubmitHandler<RegisterFormData> = (data) => {
      nextHandler(data)
    }

    // Set up the form with the data given as input to the component
    const {
      register,
      control,
      handleSubmit,
      formState: { errors },
      setValue,
      getValues,
    } = useForm<RegisterFormData>({
      mode: 'onTouched',
      defaultValues: formData,
    })
    const onSubmit = handleSubmit(submit)

    const handleOuChange = (event: any, value: any) => {
      if (value.id) {
        setOuChoice(value.id)
        setValue('ou_id', parseInt(value.id, 10))
      } else {
        setValue('ou_id', undefined)
      }
    }

    register('role_type', {
      required: t<string>('validation.roleTypeRequired'),
    })

    const getMaxDateForRoleType = (roleType: RoleTypeData | undefined) => {
      if (roleType === undefined) {
        return today
      }
      return addDays(roleType.max_days)(today)
    }

    function updateMaxDaysForRoleType(roleTypeId: any) {
      const selectedRoleTypeInfo =
        roleTypes === undefined
          ? undefined
          : roleTypes.find((rt) => rt.id === roleTypeId)
      const maxDate = getMaxDateForRoleType(selectedRoleTypeInfo)
      setTodayPlusMaxDays(maxDate)
    }

    // When the component is first rendered and there is some role_type set in
    // the input formData, the roleTypes-array is most
    // probably empty since the data has not come back from the server yet.
    // Need to have this effect here to make sure the max end date gets
    // updated when the role types are available
    useEffect(() => {
      if (formData.role_type) {
        updateMaxDaysForRoleType(formData.role_type)
      }
    }, [roleTypes])

    const handleRoleTypeChange = (event: any) => {
      if (event.target.value) {
        setValue('role_type', event.target.value)
        setSelectedRoleType(event.target.value)
        updateMaxDaysForRoleType(event.target.value)
      } else {
        setValue('role_type', undefined)
      }
    }

    function doSubmit() {
      return onSubmit()
    }

    useImperativeHandle(ref, () => ({ doSubmit }))

    const validateStartDateBeforeEndDate = (startDate: Date | undefined) => {
      if (!startDate) {
        return t('validation.startDateMustBeSet')
      }

      const roleEnd = getValues('role_end')
      if (roleEnd && startDate > roleEnd) {
        // The role end date is set, but is before the start date
        return t('validation.startDateMustBeBeforeEndDate')
      }
      return true
    }

    function getFullOptionLabel(ouData: OuData) {
      switch (i18n.language) {
        case 'en':
          return `${ouData.en}${ouData.identifier_1 ? ` (${ouData.identifier_1})` : ''
            }${ouData.identifier_2 ? ` (${ouData.identifier_2})` : ''}`

        case 'nn':
          return `${ouData.nb}${ouData.identifier_1 ? ` (${ouData.identifier_1})` : ''
            }${ouData.identifier_2 ? ` (${ouData.identifier_2})` : ''}`
        default:
          // There should always be a Norwegian bokmaal acronym set

          return `${ouData.nb}${ouData.identifier_1 ? ` (${ouData.identifier_1})` : ''
            }${ouData.identifier_2 ? ` (${ouData.identifier_2})` : ''}`
      }
    }

    if (loading) {
      return <Loading />
    }

    return (
      <>
        <Typography
          variant="h2"
          sx={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          {t('registerWizardText.registerContactInfo')}
        </Typography>
        <Typography variant="body1" sx={{ paddingBottom: '0.5rem' }}>
          {t('registerWizardText.registerPage')}
        </Typography>
        <Divider sx={{ border: '1px solid' }} />
        <form onSubmit={onSubmit}>
          <Box
            sx={{
              paddingTop: '1rem',
              paddingBottom: '4rem',
              maxWidth: '30rem',
            }}
          >
            <Stack spacing={2}>
              <TextField
                id="firstName"
                label={t('input.firstName')}
                error={!!errors.first_name}
                helperText={errors.first_name && errors.first_name.message}
                {...register(`first_name`, {
                  validate: isValidFirstName,
                })}
              />
              <TextField
                id="lastName"
                label={t('input.lastName')}
                error={!!errors.last_name}
                helperText={errors.last_name && errors.last_name.message}
                {...register(`last_name`, {
                  validate: isValidLastName,
                })}
                inputProps={{ 'data-testid': 'lastName-input-field' }}
              />
              <TextField
                id="email"
                label={t('input.email')}
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
                {...register(`email`, {
                  validate: isValidEmail,
                })}
              />
            </Stack>
          </Box>
          <Typography
            variant="h2"
            sx={{
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}
          >
            {t('registerWizardText.registerPeriod')}
          </Typography>
          <Typography variant="body1" sx={{ paddingBottom: '0.5rem' }}>
            {t('registerWizardText.registerPeriodText')}
          </Typography>
          <Divider sx={{ border: '1px solid' }} />

          <Box sx={{ paddingTop: '1rem', maxWidth: '30rem' }}>
            <Stack spacing={2}>
              <TextField
                id="roletype-select"
                select
                value={selectedRoleType}
                error={!!errors.role_type}
                label={t('input.roleType')}
                onChange={handleRoleTypeChange}
              >
                {(roleTypes === undefined ? [] : roleTypes)
                  .sort(roleTypeSort())
                  .map((roleType) => (
                    <MenuItem key={roleType.id.toString()} value={roleType.id}>
                      {i18n.language === 'en'
                        ? roleType.name_en
                        : roleType.name_nb}
                    </MenuItem>
                  ))}
              </TextField>

              <Controller
                name="ou_id"
                control={control}
                rules={{ required: true }}
                render={() => (
                  <Autocomplete
                    autoHighlight
                    disabled={ous === undefined}
                    value={
                      ouChoice && ous
                        ? ous.find((ou) => ou.id === ouChoice)
                        : null
                    }
                    options={(ous === undefined ? [] : ous).sort(
                      i18n.language === 'en' ? enSort : nbSort
                    )}
                    getOptionLabel={(option) => getFullOptionLabel(option)}
                    onChange={handleOuChange}
                    renderInput={(param) => (
                      <TextField {...param} label={t('common:ou')} />
                    )}
                  />
                )}
              />
              {!!errors.ou_id && (
                <Box sx={{ typography: 'caption', color: 'red' }}>
                  {t('validation.ouMustBeChosen')}
                </Box>
              )}

              {/* There are no particular constraints on the date pickers. It should be allowed to add a role with a start date that is in the past for instance */}
              <Controller
                name="role_start"
                control={control}
                rules={{ validate: validateStartDateBeforeEndDate }}
                defaultValue={today}
                render={({ field }) => (
                  <DatePicker
                    mask="____-__-__"
                    label={t('input.roleStartDate')}
                    maxDate={todayPlusMaxDays}
                    value={field.value}
                    inputFormat="yyyy-MM-dd"
                    onChange={(value) => {
                      field.onChange(value)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              />
              {!!errors.role_start && (
                <Box sx={{ typography: 'caption', color: 'red' }}>
                  {errors.role_start.message}
                </Box>
              )}
              <Controller
                name="role_end"
                control={control}
                rules={{
                  required: true,
                  validate: () =>
                    Number(getValues('role_end')) <= Number(todayPlusMaxDays),
                }}
                render={({ field }) => (
                  <DatePicker
                    mask="____-__-__"
                    label={t('input.roleEndDate')}
                    value={field.value}
                    minDate={today}
                    maxDate={todayPlusMaxDays}
                    inputFormat="yyyy-MM-dd"
                    onChange={(value) => {
                      field.onChange(value)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              />
              {!!errors.role_end && errors.role_end.type === 'required' && (
                <Box sx={{ typography: 'caption', color: 'red' }}>
                  {t('validation.roleEndRequired')}
                </Box>
              )}
              {!!errors.role_end && errors.role_end.type === 'validate' && (
                <Box sx={{ typography: 'caption', color: 'red' }}>
                  {t('validation.invalidEndDate')}
                </Box>
              )}

              {displayContactAtUnit && (
                <TextField
                  id="contact_person"
                  label={`${t('input.contactPersonUnit')} ${t(
                    'input.optional'
                  )}`}
                  {...register(`contact_person_unit`)}
                />
              )}

              {displayComment && (
                <TextField
                  id="comment"
                  label={`${t('input.comment')} ${t('input.optional')}`}
                  multiline
                  rows={5}
                  {...register(`comment`)}
                />
              )}
            </Stack>
          </Box>
        </form>
      </>
    )
  }
)

export default StepPersonForm

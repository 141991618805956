import { useTranslation } from 'react-i18next'
import { Container, Typography } from '@mui/material'

import LoginBox from 'components/loginBox'
import { instNameUpperCaser } from 'utils'
import { appInst } from 'appConfig'
import Page from 'components/page'

export default function LoginPage() {
  const { t } = useTranslation(['frontpage'])

  return (
    <Page>
      <Container
        sx={{
          marginTop: '4rem',
        }}
      >
        <Typography variant="h1" sx={{ paddingBottom: '1rem' }}>
          {t('header')}
        </Typography>
        <Typography variant="body1" sx={{ paddingBottom: '2rem' }}>
          {t('description', { inst: instNameUpperCaser(appInst) })}
        </Typography>
      </Container>
      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          marginBottom: '10rem',
        }}
      >
        <LoginBox header={t('sponsor')} info={t('sponsorInfo')} />
        <LoginBox header={t('guest')} info={t('guestInfo')} />
      </Container>
    </Page>
  )
}

import validator from '@navikt/fnrvalidator'
import { getYear, parseISO } from 'date-fns'
import i18n from 'i18next'
import {
  FetchedIdentity,
  FetchedRole,
  Identity,
  Role,
  Consent,
  FetchedConsent,
} from 'interfaces'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/mobile'
import parse from 'date-fns/parse'
import { parseInt } from 'lodash'

const validEmailRegex =
  /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/

export function getCookie(name: string) {
  if (!document.cookie) {
    return null
  }

  const cookies = document.cookie
    .split(';')
    .map((c) => c.trim())
    .filter((c) => c.startsWith(`${name}=`))

  if (cookies.length === 0) {
    return null
  }
  return decodeURIComponent(cookies[0].split('=')[1])
}

export function setCookie(name: string, value: string) {
  document.cookie = `${name}=${value}; path=/`
}

export function deleteCookie(name: string) {
  if (getCookie(name)) {
    setCookie(name, '; expires=Thu, 01 jan 1970 00:00:00 GMT')
  }
}

export function maybeCsrfToken() {
  const csrfToken = getCookie('csrftoken')
  if (!csrfToken) {
    return null
  }
  return {
    'X-CSRFToken': csrfToken,
  }
}

export function submitJsonOpts(method: string, data: object): RequestInit {
  return {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...maybeCsrfToken(),
    },
    body: JSON.stringify(data),
    credentials: 'same-origin',
  }
}

export function fetchJsonOpts(): RequestInit {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  }
}

export function norwegianIdNumberHasValidChecksum(digits: number[]): boolean {
  // from https://github.com/navikt/fnrvalidator/blob/master/src/validator.js
  let k1 =
    11 -
    ((3 * digits[0] +
      7 * digits[1] +
      6 * digits[2] +
      1 * digits[3] +
      8 * digits[4] +
      9 * digits[5] +
      4 * digits[6] +
      5 * digits[7] +
      2 * digits[8]) %
      11)
  let k2 =
    11 -
    ((5 * digits[0] +
      4 * digits[1] +
      3 * digits[2] +
      2 * digits[3] +
      7 * digits[4] +
      6 * digits[5] +
      5 * digits[6] +
      4 * digits[7] +
      3 * digits[8] +
      2 * k1) %
      11)

  if (k1 === 11) k1 = 0
  if (k2 === 11) k2 = 0

  return k1 < 10 && k2 < 10 && k1 === digits[9] && k2 === digits[10]
}

export function isValidSoNumber(data: string): boolean {
  // see https://lovas.info/2013/12/01/identitetsnummer-i-norge/

  const day = data.slice(0, 2)
  const month = data.slice(2, 4)
  const year = data.slice(4, 6)
  const pnr = data.slice(6, 11)

  // this part should always start with 1
  if (pnr.slice(0, 1) !== '1') {
    return false
  }

  const actualMonth = parseInt(month) - 50
  const date = new Date(
    year === '00' ? 2000 : parseInt(year),
    actualMonth - 1,
    parseInt(day)
  )

  // date must be a valid date
  if (
    !(
      date &&
      date.getMonth() + 1 === actualMonth &&
      date.getDate() === parseInt(day)
    )
  ) {
    return false
  }

  // checksum must match
  const digits = data.split('').map((x) => parseInt(x))
  if (!norwegianIdNumberHasValidChecksum(digits)) {
    return false
  }
  return true
}

export function isValidFnr(
  data: string | undefined,
  allowEmpty = false,
  allowSoNumber = false
): boolean | string {
  if (!data) {
    if (allowEmpty) {
      return true
    }
    return i18n.t<string>('common:validation.invalidIdNumber').toString()
  }
  const validation = validator.idnr(data as string)
  if (
    validation.status === 'valid' &&
    ['fnr', 'dnr'].includes(validation.type)
  ) {
    return true
  }
  if (allowSoNumber && isValidSoNumber(data)) {
    return true
  }
  // TypeScript complains if toString is not used on the function result
  return i18n.t<string>('common:validation.invalidIdNumber').toString()
}

export function isValidNationalIdCardNumber(
  data: string | undefined,
  allowEmpty = false
): boolean | string {
  // Currently no way of validating
  if (!data) {
    if (allowEmpty) {
      return true
    }
    return true
  }
  return true
}

export function isValidMobilePhoneNumber(
  data: string | undefined
): true | string {
  if (!data) {
    return i18n.t<string>('common:validation.invalidMobilePhoneNumber')
  }
  if (isValidPhoneNumber(data)) {
    return true
  }
  return i18n.t<string>('common:validation.invalidMobilePhoneNumber')
}

export async function isValidEmail(data: string | undefined) {
  if (!data) {
    return i18n.t<string>('common:validation.emailRequired')
  }
  if (validEmailRegex.test(data)) {
    const message = await fetch(
      `/api/ui/v1/email/${data}`,
      fetchJsonOpts()
    ).then((res) => {
      if (res.ok) {
        return i18n.t<string>('common:validation.existingEmail')
      }
      return true
    })
    return message
  }
  return i18n.t<string>('common:validation.invalidEmail')
}

function stringContainsIllegalChars(string: string): boolean {
  // Only allow the following characters:
  // ----- Basic Latin -----
  // U+0020 (Space)
  // U+0027 (Apostrophe)
  // U+002D (Hyphen-minus)
  // U+002E (Full stop)
  // U+0041 - U+005A (Latin Alphabet: Uppercase)
  // U+0061 - U+007A (Latin Alphabet: Lowercase)
  // ----- Latin-1 Supplement -----
  // U+00C0 - U+00D6 (Letters: Uppercase)
  // U+00D8 - U+00DE (Letters: Uppercase)
  // U+00DF - U+00F6 (Letters: Lowercase)
  // U+00F8 - U+00FF (Letters: Lowercase)
  // ----- Latin Extended-A -----
  // U+0100 - U+017F (European Latin)

  // eslint-disable-next-line no-control-regex
  return /[^\u0020\u0027\u002D\u002E\u0041-\u005A\u0061-\u007A\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F]/g.test(
    string
  )
}

export function isValidFirstName(data: string | undefined): string | true {
  if (!data) {
    return i18n.t<string>('common:validation.firstNameRequired')
  }
  if (stringContainsIllegalChars(data)) {
    return i18n.t<string>('common:validation.firstNameContainsInvalidChars')
  }
  return true
}

export function isValidLastName(data: string | undefined): string | true {
  if (!data) {
    return i18n.t<string>('common:validation.lastNameRequired')
  }
  if (stringContainsIllegalChars(data)) {
    return i18n.t<string>('common:validation.lastNameContainsInvalidChars')
  }
  return true
}

/**
 * Splits a phone number into a country code and the national number.
 *
 * @param phoneNumber The phone number to split
 */
export function splitPhoneNumber(phoneNumber: string): [string, string] {
  const parsedNumber = parsePhoneNumber(phoneNumber)

  return [
    parsedNumber.countryCallingCode.toString(),
    parsedNumber.nationalNumber.toString(),
  ]
}

export function getLocalized(
  obj: any,
  prefix: string,
  locale: string
): string | null {
  const key = prefix + locale
  return Object.prototype.hasOwnProperty.call(obj, key) ? obj[key] : null
}

export function parseRole(role: FetchedRole): Role {
  return {
    id: role.id,
    name_nb: role.name_nb,
    name_en: role.name_en,
    ou_nb: role.ou_nb,
    ou_en: role.ou_en,
    start_date: role.start_date == null ? undefined : parseISO(role.start_date),
    end_date: parseISO(role.end_date),
    max_days: role.max_days,
    contact_person_unit: role.contact_person_unit,
    comments: role.comments,
    sponsor_name: role.sponsor_name,
    sponsor_feide_id: role.sponsor_feide_id,
    ou_id: role.ou_id,
  }
}

export function parseConsent(cons: FetchedConsent): Consent {
  return {
    id: cons.id,
    type: cons.type,
    choice: cons.choice,
    consent_given_at: cons.consent_given_at
      ? parseISO(cons.consent_given_at)
      : null,
  }
}

export function parseIdentity(
  identity: FetchedIdentity | null
): Identity | null {
  if (identity == null) {
    return null
  }
  return {
    id: identity.id,
    type: identity.type,
    value: identity.value,
    verified_at: identity.verified_at ? parseISO(identity.verified_at) : null,
    verified: identity.verified,
    verified_by: identity.verified_by,
    source: identity.source,
  }
}

/**
 * Method to ensure the correct "styled" case of a institution acronym.
 *
 * @param instName An institution acronym in any case
 * @returns The correct "styled" case acronym, or the input string if no match.
 */
export function instNameUpperCaser(instName: String): String {
  switch (instName.toLocaleLowerCase()) {
    case 'uio':
      return 'UiO'
    case 'uib':
      return 'UiB'
    case 'ntnu':
      return 'NTNU'
    case 'uit':
      return 'UiT'
    default:
      return instName
  }
}

/**
 * Get the name of a role matching chosen language with fallback to nb/en if missing
 * @param role an object of type Role
 * @returns the name matching the language or fallback if missing
 */
export function getRoleName(role: Role) {
  if (i18n.language === 'en') {
    return role.name_en ? role.name_en : role.name_nb
  }
  return role.name_nb ? role.name_nb : role.name_en
}

/**
 * Get the ou name of a role matching chosen language with fallback to nb/en if missing
 * @param role an object of type Role
 * @returns the ou name matching the language or fallback if missing
 */
export function getRoleOuName(role: Role) {
  if (i18n.language === 'en') {
    return role.ou_en ? role.ou_en : role.ou_nb
  }
  return role.ou_nb ? role.ou_nb : role.ou_en
}

/**
 * Note the the input is assumed to be either a D-number or a "fødselsnummer". Other types such as H-numbers are not supported.
 *
 * @param nationalId D-number or "fødselsnummer"
 */
function isDnr(nationalId: string): boolean {
  return parseInt(nationalId.substring(0, 1), 10) >= 4
}

export function isFemaleBasedOnNationalId(nationalId: string): boolean {
  if (isDnr(nationalId)) {
    return parseInt(nationalId.charAt(10), 10) % 2 === 0
  }
  return parseInt(nationalId.charAt(8), 10) % 2 === 0
}

/**
 * The ID-number format is scheduled to change in the future:
 * https://www.skatteetaten.no/deling/opplysninger/folkeregisteropplysninger/pid/
 *
 * Do not attempt a suggestion if we are at the time when the format will change.
 */
function shouldAttemptSuggestion(): boolean {
  const currentYear = getYear(new Date())

  // Keeping it simple, if this code is still running and should be revised.
  // The new numbers are scheduled to appear in 2032
  return currentYear <= 2031
}

export function extractGenderOrBlank(nationalId?: string): string {
  if (
    nationalId == null ||
    nationalId === '' ||
    isValidFnr(nationalId) !== true ||
    !shouldAttemptSuggestion()
  ) {
    return ''
  }

  if (isFemaleBasedOnNationalId(nationalId)) {
    return 'female'
  }
  return 'male'
}

/**
 * Gives a guess of the birthdate with century included.
 *
 * @param dateOfBirth a date on the form ddMMyy
 */
function suggestBirthDate(dateOfBirth: string): Date {
  const currentYear = getYear(new Date())
  const year = dateOfBirth.substring(4, 6)
  const yearAsInt = parseInt(year)
  let century = '20'

  // Check that the year the person is born is not a year in the future,
  // given he is born in the 21th century. Also assuming he is born in
  // the 21th century, check that he is older than 15 years, if not
  // then assume he is born in the 20th century
  if (
    yearAsInt + 2000 > currentYear ||
    !(currentYear - 2000 - yearAsInt > 15)
  ) {
    century = '19'
  }
  return parse(
    dateOfBirth.substring(0, 4) + century + dateOfBirth.substring(4, 6),
    'ddMMyyyy',
    new Date()
  )
}

function extractBirthdateFromFnr(nationalId: string): Date {
  return suggestBirthDate(nationalId.substring(0, 6))
}

function extractBirthdateFromDnumber(nationalId: string): Date {
  return suggestBirthDate(
    (parseInt(nationalId.charAt(0), 10) - 4).toString(10) +
      nationalId.substring(1, 6)
  )
}

export function extractBirthdateFromNationalId(
  nationalId?: string
): Date | null {
  if (
    nationalId == null ||
    nationalId === '' ||
    isValidFnr(nationalId) !== true ||
    !shouldAttemptSuggestion()
  ) {
    return null
  }

  if (isDnr(nationalId)) {
    return extractBirthdateFromDnumber(nationalId)
  }
  return extractBirthdateFromFnr(nationalId)
}

import { useTranslation } from 'react-i18next'

import Spinner from 'components/animations/spinner'
import styled from '@emotion/styled/macro'

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

function Loading() {
  const { t } = useTranslation(['common'])

  return (
    <SpinnerWrapper>
      <Spinner />
      {t('common:loading')}
    </SpinnerWrapper>
  )
}

export default Loading

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Typography } from '@mui/material'
import { useUserContext } from 'contexts'

const GuestSuccessStep = () => {
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()
  const { getUserInfo } = useUserContext()

  // Refetch user info after submission, so that the front page doesn't redirect us
  // back because of outdated info
  useEffect(() => {
    getUserInfo()
  }, [])
  return (
    <>
      <Typography
        sx={{
          paddingTop: '1rem',
          paddingBottom: '1rem',
        }}
        variant="h2"
      >
        {t('thankYou')}
      </Typography>
      <Typography sx={{ marginTop: '2rem' }} paragraph>
        {t('guestSubmitSuccessDescription')}
      </Typography>

      <Button
        color="secondary"
        sx={{
          marginTop: '2rem',
        }}
        onClick={() => {
          navigate('/')
        }}
      >
        {t('button.seeRegisteredInfo')}
      </Button>
    </>
  )
}

export default GuestSuccessStep

import React from 'react'
import { Button } from '@mui/material'

interface IHrefButton {
  to: string
  onClick?: () => {}
  children: React.ReactNode
}

function HrefButton({ to, onClick, children }: IHrefButton) {
  return (
    <Button variant="contained" href={to} color="secondary" onClick={onClick}>
      {children}
    </Button>
  )
}

HrefButton.defaultProps = {
  onClick: () => {},
}

// eslint-disable-next-line import/prefer-default-export
export { HrefButton }

import PersonIcon from '@mui/icons-material/Person'
import { Box, IconButton } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

interface OverviewGuestButtonProperties {
  backArrow?: string
}

export default function OverviewGuestButton(
  properties: OverviewGuestButtonProperties
) {
  const { backArrow } = properties
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()

  const goToGuestOverview = () => {
    navigate('/guestregister')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'space-evenly',
      }}
    >
      {backArrow !== undefined ? (
        <ArrowBackIcon
          onClick={() => {
            navigate(backArrow)
          }}
        />
      ) : (
        <ArrowBackIcon visibility="hidden" />
      )}

      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <IconButton onClick={goToGuestOverview}>
          <PersonIcon
            fontSize="large"
            sx={{
              borderRadius: '2rem',
              borderStyle: 'solid',
              borderColor: 'secondary.main',
              fill: 'white',
              backgroundColor: 'secondary.main',
            }}
          />
        </IconButton>
        <Box
          sx={{
            typography: 'caption',
          }}
        >
          {t('yourGuestAccount')}
        </Box>
      </Box>

      <IconButton disabled>
        <ArrowForwardIcon visibility="hidden" />
      </IconButton>
    </Box>
  )
}

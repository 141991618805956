import { ThemeOptions } from '@mui/material'

// Note that a few global styles are set by CssBaseline in 'routes/index.tsx

const mainTheme: ThemeOptions = {
  greg: {
    appMinWidth: '60rem',
    appMaxWidth: '110rem',
    deactivatedColor: '#C9C9C9',
    textWhite: 'white',
    footerLinkBgColor: '#202020',
  },
  typography: {
    fontSize: 16,
    h1: {
      fontSize: '2.75rem', // 44px
    },
    h2: {
      fontSize: '1.75rem', // 28px
    },
    h3: {
      fontSize: '1.375rem', // 22px
    },
    body1: {
      fontSize: '1.125rem', // 18px
    },
    body2: {
      lineHeight: 1.5,
    },
    button: {
      fontSize: '0.875rem', // 14px
    },
  },

  // No palette section defined here, it is up to the different institutions to define colours that override the default ones from Material UI
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
}

export default mainTheme

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { LocalizationProvider } from '@mui/lab'
import { BrowserRouter as Router } from 'react-router-dom'
import 'i18n'
import * as Sentry from '@sentry/browser'

import getTheme from 'themes'
import App from 'routes'
import { UserProvider } from 'providers'
import { sentryEnabled, sentryDsn, sentryEnvironment, appVersion } from 'appConfig'
import reportWebVitals from './reportWebVitals'
import FeatureProvider from './providers/featureProvider'

import './index.css'

// Initialize sentry
if (sentryEnabled) {
  Sentry.init({
    dsn: sentryDsn,
    release: appVersion,
    environment: sentryEnvironment,
  })
}

function appRoot() {
  return (
    <React.StrictMode>
      <Router>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FeatureProvider>
            <ThemeProvider theme={getTheme()}>
              <UserProvider>
                <Suspense fallback={<CircularProgress />}>
                  <App />
                </Suspense>
              </UserProvider>
            </ThemeProvider>
          </FeatureProvider>
        </LocalizationProvider>
      </Router>
    </React.StrictMode>
  )
}

ReactDOM.render(React.createElement(appRoot), document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import styled from '@emotion/styled/macro'

const Spinner = styled.div`
  position: relative;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0;
  margin-right: 1rem;
  margin-top: 0;
  border: 3px solid rgba(200, 200, 200);
  border-width: 3px;
  border-radius: 50%;
  border-top-color: rgba(50, 50, 50);
  animation: spin 0.8s linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

export default Spinner

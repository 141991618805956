import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button } from '@mui/material'
import Page from 'components/page'

import { useNavigate } from 'react-router-dom'
import format from 'date-fns/format'
import { RegisterFormData } from './formData'
import StepSummary from './stepSummary'
import StepPersonForm from './stepPersonForm'
import { PersonFormMethods } from './personFormMethods'
import SubmitState from './submitState'
import SponsorGuestButtons from '../../components/sponsorGuestButtons'
import { submitJsonOpts } from '../../../utils'
import StepSubmitSuccess from './stepSubmitSuccess'
import ServerErrorReport, {
  ServerErrorReportData,
} from '../../../components/errorReport'
import useOus from '../../../hooks/useOus'
import useRoleTypes from '../../../hooks/useRoleTypes'

enum Steps {
  RegisterStep = 0,
  SummaryStep = 1,
  SuccessStep = 2,
}

/**
 *
 * This component controls the invite process where the sponsor
 * enters the initial information about a guest.
 *
 */
export default function StepRegistration() {
  const { t } = useTranslation(['common'])
  const [formData, setFormData] = useState<RegisterFormData>({
    first_name: undefined,
    last_name: undefined,
    role_type: undefined,
    // Having role_start to be nullable caused problems when specifying a default value, so instead having them as non-null and use today as the default date here
    role_start: new Date(),
    role_end: null,
    comment: undefined,
    ou_id: undefined,
    email: undefined,
  })
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(0)
  const personFormRef = useRef<PersonFormMethods>(null)
  const [submitState, setSubmitState] = useState(SubmitState.NotSubmitted)
  const [submitErrorReport, setSubmitErrorReport] =
    useState<ServerErrorReportData>()
  const [formDataErrorReport, setFormDataErrorReport] =
    useState<ServerErrorReportData>()
  const [submitPartialSuccess, setSubmitPartialSuccess] =
    useState<ServerErrorReportData>()

  // The organizational unit and role types are not used by this component, but
  // loading them here anyways to make sure that they can be loaded using the
  // hooks. If they cannot, an error message will be shown to the user
  const { ous } = useOus()
  const roleTypes = useRoleTypes()

  const handleNext = () => {
    if (activeStep === 0) {
      if (personFormRef.current) {
        personFormRef.current.doSubmit()
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  function handleSubmitErrorResponse(res: Response) {
    // Try to extract data from body of error message
    res
      .text()
      .then((text) => {
        setSubmitState(SubmitState.SubmitFailure)

        // Some error responses have a code that is used to look up a translated text.
        // Attempt to parse the message text and extract the code
        const jsonResponse = JSON.parse(text)
        const errorText =
          jsonResponse.code === undefined
            ? text
            : t(`error.codes.${jsonResponse.code}`)

        setSubmitErrorReport({
          errorHeading: t('error.invitationCreationFailedHeader'),
          statusCode: res.status,
          statusText: res.statusText,
          errorBodyText: errorText,
        })
      })
      .catch(() => {
        // Extracting data from body failed, just show an error message with no body text
        setSubmitErrorReport({
          errorHeading: t('error.invitationCreationFailedHeader'),
          statusCode: res.status,
          statusText: res.statusText,
          errorBodyText: undefined,
        })
        setSubmitState(SubmitState.SubmitFailure)
      })
  }

  function handleSubmitOkResponse(res: Response) {
    // The invite was created, but there may still be some warning
    res
      .json()
      .then((jsonResponse) => {
        // If there is a body then it should have some json-content

        if ('code' in jsonResponse) {
          // There is something in the body indicating a warning
          setSubmitPartialSuccess({
            errorHeading: t('error.partialSubmitSuccess'),
            statusCode: undefined,
            statusText: undefined,
            errorBodyText: t(`error.codes.${jsonResponse.code}`),
          })
        }
      })
      .catch()
      .finally(() => {
        setSubmitState(SubmitState.SubmitSuccess)
        setActiveStep(Steps.SuccessStep)
      })
  }

  const registerGuest = () => {
    const payload = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      role: {
        type: formData.role_type,
        start_date:
          formData.role_start === null
            ? null
            : format(formData.role_start as Date, 'yyyy-MM-dd'),
        end_date:
          formData.role_end === null
            ? null
            : format(formData.role_end as Date, 'yyyy-MM-dd'),
        contact_person_unit: formData.contact_person_unit,
        comments: formData.comment,
        orgunit: formData.ou_id,
      },
    }

    fetch('/api/ui/v1/invite/', submitJsonOpts('POST', payload))
      .then((res) => {
        if (!res.ok) {
          handleSubmitErrorResponse(res)
        } else {
          handleSubmitOkResponse(res)
        }
        return null
      })
      .catch(() => {
        setSubmitState(SubmitState.SubmitFailure)
      })
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setFormDataErrorReport(undefined)
    setSubmitErrorReport(undefined)
  }

  const handleForwardFromRegister = (
    updateFormData: RegisterFormData
  ): void => {
    setFormData(updateFormData)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleCancel = () => {
    navigate('/')
  }

  useEffect(() => {
    if (ous === undefined || roleTypes === undefined) {
      // The organisational units or role types are still loading
      return
    }

    if (ous.length === 0 || roleTypes.length === 0) {
      // These arrays should have values. There is no information
      // about the status code at this level, since the values come
      // from hooks and any server errors are handled there
      setFormDataErrorReport({
        errorHeading: t('error.errorLoadOusRoleTypeHeading'),
        statusCode: undefined,
        statusText: undefined,
        errorBodyText: t('error.errorLoadOusRoleType'),
      })
    } else {
      // Clear any errors
      setFormDataErrorReport(undefined)
    }
  }, [ous, roleTypes])

  return (
    <Page>
      <SponsorGuestButtons registerNewGuestActive />
      {/* Current page in wizard */}
      <Box sx={{ width: '100%' }}>
        {activeStep === Steps.RegisterStep && (
          <StepPersonForm
            nextHandler={handleForwardFromRegister}
            formData={formData}
            ref={personFormRef}
          />
        )}
        {activeStep === Steps.SummaryStep && (
          <StepSummary formData={formData} />
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          color: 'primary.main',
          paddingBottom: '1rem',
        }}
      >
        {activeStep === Steps.RegisterStep && (
          <Button
            data-testid="button-next"
            variant="contained"
            color="secondary"
            sx={{ mr: 1 }}
            onClick={handleNext}
          >
            {t('button.next')}
          </Button>
        )}

        {activeStep === Steps.SummaryStep && (
          <>
            <Button
              onClick={handleBack}
              variant="contained"
              color="secondary"
              disabled={submitState === SubmitState.SubmitSuccess}
              sx={{ mr: 1 }}
            >
              {t('button.back')}
            </Button>

            <Button
              onClick={registerGuest}
              variant="contained"
              color="secondary"
              disabled={submitState === SubmitState.SubmitSuccess}
              sx={{ mr: 1 }}
            >
              {t('button.save')}
            </Button>
          </>
        )}

        {activeStep !== Steps.SuccessStep && (
          <Button
            sx={{
              color: (theme) => theme.greg.wizardButtonColor,
            }}
            onClick={handleCancel}
            color="secondary"
            disabled={submitState === SubmitState.SubmitSuccess}
          >
            {t('button.cancel')}
          </Button>
        )}
      </Box>

      {activeStep === Steps.SuccessStep && <StepSubmitSuccess />}

      {submitState === SubmitState.SubmitFailure &&
        submitErrorReport !== undefined && (
          <ServerErrorReport
            errorHeading={submitErrorReport?.errorHeading}
            statusCode={submitErrorReport?.statusCode}
            statusText={submitErrorReport?.statusText}
            errorBodyText={submitErrorReport?.errorBodyText}
          />
        )}

      {formDataErrorReport !== undefined && (
        <ServerErrorReport
          errorHeading={formDataErrorReport.errorHeading}
          statusCode={undefined}
          statusText={undefined}
          errorBodyText={formDataErrorReport.errorBodyText}
        />
      )}

      {submitPartialSuccess !== undefined && (
        <ServerErrorReport
          errorHeading={submitPartialSuccess.errorHeading}
          statusCode={undefined}
          statusText={undefined}
          errorBodyText={submitPartialSuccess.errorBodyText}
        />
      )}
    </Page>
  )
}

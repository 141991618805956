import { useEffect, useState } from 'react'
import _ from 'lodash'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  Paper,
  Stack, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import SearchIcon from '@mui/icons-material/Search'

import Loading from 'components/loading'
import Page from 'components/page'
import { differenceInDays, format, isBefore } from 'date-fns'
import { Guest, Role } from 'interfaces'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getRoleName, getRoleOuName } from 'utils'
import SponsorGuestButtons from '../../components/sponsorGuestButtons'
import DownloadGuestsButton from '../../components/downloadGuestsButton'

interface GuestProps {
  persons: Guest[]
}

interface FilterInactiveGuestsCheckboxProps {
  isChecked: boolean
  onChange: () => void
}

interface PersonLineProps {
  person: Guest
  role: Role
}

interface StatusProps {
  person: Guest
  role: Role
}

interface GuestTableProps {
  guests: Guest[]
  emptyText: string
  marginWidth: number
  unitFilters: string[]
}

interface FrontPageProps {
  guests: Guest[]
  loading: boolean
}

type SortDirection = 'desc' | 'asc'
type SortField = 'name' | 'role' | 'status' | 'endDate' | 'department'

interface GuestRole {
  guest: Guest
  role: Role
}

const StyledTableRow = styled(TableRow)({
  borderTop: '0',
  borderLeft: '0',
  borderRight: '0',
  borderBottom: '2px solid',
  borderColor: 'black',
  borderRadius: '0',
})

const StyledAccordion = styled(Accordion)({
  borderStyle: 'none',
  boxShadow: 'none',
  margin: 'auto',
})

const StyledAccordionSummary = styled(AccordionSummary)({
  borderStyle: 'solid',
  borderColor: 'black',
  borderWidth: '0.125rem',
  borderRadius: '1%',
})

const StyledTableHeadCell = styled(TableCell)({
  fontWeight: 'bold',
})

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderTop: '0',
  borderLeft: '0',
  borderRight: '0',
  borderBottom: '0.1875rem solid',
  borderColor: theme.palette.secondary.main,
  borderRadius: '0',
}))

function calculateStatus(person: Guest, role: Role): [string, number] {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  let status = ''
  const days = differenceInDays(role.end_date, today)

  if (!person.registered) {
    status = 'waitingForGuest'
    if (person.invitation_status === 'invalidEmail') {
      status = 'invalidEmail'
    } else if (person.invitation_status !== 'active') {
      status = 'invitationExpired'
    }
  } else if (person.registered && !person.verified) {
    status = 'waitingForSponsor'
  } else if (person.registered && person.verified) {
    if (isBefore(role.end_date, today)) {
      status = 'expired'
    } else if (days <= 7) {
      status = 'expiring'
    } else {
      status = 'active'
    }
  }
  return [status, days]
}

function Status({ person, role }: StatusProps) {
  const { t } = useTranslation('common')
  const [status, days] = calculateStatus(person, role)

  switch (status) {
    case 'active':
      return (
        <TableCell sx={{ color: 'success.main' }} align="left">
          {t('statusText.active')}
        </TableCell>
      )
    case 'expired':
      return (
        <TableCell sx={{ color: 'error.main' }} align="left">
          {t('statusText.expired')}
        </TableCell>
      )
    case 'waitingForGuest':
      return (
        <TableCell sx={{ color: 'blue' }} align="left">
          {t('statusText.waitingForGuest')}
        </TableCell>
      )
    case 'waitingForSponsor':
      return (
        <TableCell sx={{ color: 'blue' }} align="left">
          {t('statusText.waitingForSponsor')}
        </TableCell>
      )
    case 'invitationExpired':
      return (
        <TableCell sx={{ color: 'error.main' }} align="left">
          {t('statusText.invitationExpired')}
        </TableCell>
      )
    case 'expiring':
      return (
        <TableCell sx={{ color: 'blue' }} align="left">
          <Trans t={t} i18nKey="statusText.expiring" count={days} />
        </TableCell>
      )
    case 'invalidEmail':
      return (
        <TableCell sx={{ color: 'error.main' }} align="left">
          <Trans t={t} i18nKey="statusText.invalidEmail" count={days} />
        </TableCell>
      )
    default:
      return (
        <TableCell sx={{ color: 'error.main' }} align="left">
          {t('statusText.waiting')}
        </TableCell>
      )
  }
}

function createGuestsRoles(guests: Guest[]): GuestRole[] {
  const guestRoles: GuestRole[] = []
  guests.forEach((guest) =>
    guest.roles.forEach((role) => {
      guestRoles.push({ guest, role })
    })
  )
  return guestRoles
}

function sortByName(
  guestA: Guest,
  guestB: Guest,
  direction: SortDirection
): number {
  const firstA = guestA.first.toLowerCase()
  const firstB = guestB.first.toLowerCase()
  if (firstA < firstB) {
    return direction === 'asc' ? -1 : 1
  }
  if (firstA > firstB) {
    return direction === 'asc' ? 1 : -1
  }
  return 0
}

function sortGuestsByName(guests: Guest[], direction: SortDirection) {
  return createGuestsRoles(guests).sort((a, b) =>
    sortByName(a.guest, b.guest, direction)
  )
}

function sortGuestRolesByEndDate(
  guestRole: GuestRole[],
  direction: SortDirection
): GuestRole[] {
  return guestRole.sort((a, b) => {
    const aEndDate = a.role.end_date.getTime()
    const bEndDate = b.role.end_date.getTime()

    if (aEndDate < bEndDate) {
      return direction === 'asc' ? -1 : 1
    }
    if (aEndDate > bEndDate) {
      return direction === 'asc' ? 1 : -1
    }
    // Secondary sort on guest name, always asc
    return sortByName(a.guest, b.guest, 'asc')
  })
}

function sortByStatus(guests: Guest[], direction: SortDirection): GuestRole[] {
  let activeRoleAndPerson: { guest: Guest; role: Role }[] = []
  let expiringRoleAndPerson: { guest: Guest; role: Role }[] = []
  let expiredRoleAndPerson: { guest: Guest; role: Role }[] = []

  guests.forEach((guest) =>
    guest.roles.forEach((role) => {
      const [status] = calculateStatus(guest, role)
      if (status === 'active') {
        activeRoleAndPerson.push({ guest, role })
      } else if (status === 'expiring') {
        expiringRoleAndPerson.push({ guest, role })
      } else {
        expiredRoleAndPerson.push({ guest, role })
      }
    })
  )

  // Always use 'asc' for secondary sorting
  activeRoleAndPerson = sortGuestRolesByEndDate(activeRoleAndPerson, 'asc')
  expiringRoleAndPerson = sortGuestRolesByEndDate(expiringRoleAndPerson, 'asc')
  expiredRoleAndPerson = sortGuestRolesByEndDate(expiredRoleAndPerson, 'asc')

  if (direction === 'asc') {
    return expiringRoleAndPerson
      .concat(activeRoleAndPerson)
      .concat(expiredRoleAndPerson)
  }
  return expiredRoleAndPerson
    .concat(activeRoleAndPerson)
    .concat(expiringRoleAndPerson)
}

function sortGuestsByRoleName(
  guests: Guest[],
  direction: SortDirection
): GuestRole[] {
  return createGuestsRoles(guests).sort((a, b) => {
    const aRoleName = getRoleName(a.role).toLowerCase()
    const bRoleName = getRoleName(b.role).toLowerCase()
    if (aRoleName < bRoleName) {
      return direction === 'asc' ? -1 : 1
    }
    if (aRoleName > bRoleName) {
      return direction === 'asc' ? 1 : -1
    }
    // Secondary sort on guest name, always asc
    return sortByName(a.guest, b.guest, 'asc')
  })
}

function sortGuestsByDepartmentName(
  guests: Guest[],
  direction: SortDirection
): GuestRole[] {
  return createGuestsRoles(guests).sort((a, b) => {
    const aRoleOUName = getRoleOuName(a.role).toLowerCase()
    const bRoleOUName = getRoleOuName(b.role).toLowerCase()
    if (aRoleOUName < bRoleOUName) {
      return direction === 'asc' ? -1 : 1
    }
    if (aRoleOUName > bRoleOUName) {
      return direction === 'asc' ? 1 : -1
    }
    // Secondary sort on guest name, always asc
    return sortByName(a.guest, b.guest, 'asc')
  })
}

function sortGuestsAndRoles(
  guests: Guest[],
  sortField: SortField,
  direction: SortDirection,
  unitFilters?: string[]
): GuestRole[] {
  let guestRoles: GuestRole[]

  switch (sortField) {
    case 'department':
      guestRoles = sortGuestsByDepartmentName(guests, direction)
      break
    case 'endDate':
      guestRoles = sortGuestRolesByEndDate(createGuestsRoles(guests), direction)
      break
    case 'name':
      guestRoles = sortGuestsByName(guests, direction)
      break
    case 'role':
      guestRoles = sortGuestsByRoleName(guests, direction)
      break
    case 'status':
      guestRoles = sortByStatus(guests, direction)
      break
    default:
      // Fallback to original sort
      guestRoles = createGuestsRoles(guests)
      break
  }

  if (unitFilters && unitFilters.length > 0) {
    return guestRoles.filter(({ role }) =>
      unitFilters.includes(role.ou_id.toString())
    )
  }

  return guestRoles
}

function PersonLine({ person, role }: PersonLineProps) {
  const [t] = useTranslation(['common'])

  return (
    <StyledTableRow>
      <TableCell component="th" scope="row">
        {`${person.first} ${person.last}`}
      </TableCell>
      <TableCell align="left">{getRoleName(role)}</TableCell>
      <Status person={person} role={role} />
      <TableCell align="left"> {format(role.end_date, 'yyyy-MM-dd')}</TableCell>
      <TableCell align="left">{getRoleOuName(role)}</TableCell>
      <TableCell align="left">
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={`/sponsor/guest/${person.pid}`}
        >
          {t('common:details')}
        </Button>
      </TableCell>
    </StyledTableRow>
  )
}

function PersonTableBody({
  guests,
  orderBy,
  direction,
  unitFilters,
}: {
  guests: Guest[]
  orderBy: SortField
  direction: SortDirection
  unitFilters: string[] | undefined
}) {
  const { t } = useTranslation('common')

  const filteredSortedGuests = sortGuestsAndRoles(
    guests,
    orderBy,
    direction,
    unitFilters
  )
  if (filteredSortedGuests.length === 0) {
    return (
      <StyledTableRow>
        <TableCell>{t('foundNoGuests')}</TableCell>
      </StyledTableRow>
    )
  }

  return (
    <>
      {filteredSortedGuests.map((personRole) => (
        <PersonLine
          key={`${personRole.guest.first} ${personRole.guest.last} ${personRole.role.id}`}
          role={personRole.role}
          person={personRole.guest}
        />
      ))}
    </>
  )
}

function GuestTable({
  guests,
  emptyText,
  marginWidth,
  unitFilters,
}: GuestTableProps) {
  const { t } = useTranslation('common')

  const [direction, setDirection] = useState<SortDirection>('asc')
  const [orderBy, setOrderBy] = useState<SortField>('status')

  const handleRequestSort =
    (sortBy: SortField) => (event: React.MouseEvent<unknown>) => {
      event.preventDefault()
      const isAsc = orderBy === sortBy && direction === 'asc'
      setDirection(isAsc ? 'desc' : 'asc')
      setOrderBy(sortBy)
    }

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 'none',
        borderRadius: '0rem',
        minWidth: marginWidth,
      }}
    >
      <Table sx={{ minWidth: marginWidth }} aria-label="simple table">
        <StyledTableHead>
          <TableRow>
            <StyledTableHeadCell>
              <TableSortLabel
                key="name"
                active={orderBy === 'name'}
                direction={orderBy === 'name' ? direction : 'asc'}
                onClick={handleRequestSort('name')}
              >
                {t('common:name')}
              </TableSortLabel>
            </StyledTableHeadCell>
            <StyledTableHeadCell>
              <TableSortLabel
                key="role"
                active={orderBy === 'role'}
                direction={orderBy === 'role' ? direction : 'asc'}
                onClick={handleRequestSort('role')}
              >
                {t('common:role')}
              </TableSortLabel>
            </StyledTableHeadCell>

            <StyledTableHeadCell>
              <TableSortLabel
                key="status"
                active={orderBy === 'status'}
                direction={orderBy === 'status' ? direction : 'asc'}
                onClick={handleRequestSort('status')}
              >
                {t('common:status')}
              </TableSortLabel>
            </StyledTableHeadCell>
            <StyledTableHeadCell>
              <TableSortLabel
                key="endDate"
                active={orderBy === 'endDate'}
                direction={orderBy === 'endDate' ? direction : 'asc'}
                onClick={handleRequestSort('endDate')}
              >
                {t('common:endDate')}
              </TableSortLabel>
            </StyledTableHeadCell>
            <StyledTableHeadCell>
              <TableSortLabel
                key="department"
                active={orderBy === 'department'}
                direction={orderBy === 'department' ? direction : 'asc'}
                onClick={handleRequestSort('department')}
              >
                {t('common:department')}
              </TableSortLabel>
            </StyledTableHeadCell>
            <StyledTableHeadCell />
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {guests.length > 0 ? (
            <PersonTableBody
              guests={guests}
              orderBy={orderBy}
              direction={direction}
              unitFilters={unitFilters}
            />
          ) : (
            <StyledTableRow>
              <TableCell>{emptyText}</TableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function InvitedGuests({ persons }: GuestProps) {
  const [activeExpanded, setActiveExpanded] = useState(false)

  // Show guests that have not responded to the invite yet
  let guests = persons.length > 0 ? persons : []

  if (guests.length > 0) {
    guests = guests.filter((person) => !person.registered)
  }
  const [t] = useTranslation(['common'])
  return (
    <StyledAccordion
      expanded={activeExpanded}
      onChange={() => {
        setActiveExpanded(!activeExpanded)
      }}
    >
      <StyledAccordionSummary
        expandIcon={<ArrowUpwardIcon sx={{ fill: 'black' }} />}
      >
        <Typography variant="h2">
          {`${t('common:sentInvitations')} (${guests.length})`}
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body1"
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          {t('common:sentInvitationsDescription')}
        </Typography>
        <GuestTable
          guests={guests}
          emptyText={t('common:noInvitations')}
          unitFilters={[]}
          marginWidth={650}
        />
      </AccordionDetails>
    </StyledAccordion>
  )
}

interface UnitFilterSelectProps {
  guests: Guest[]
  onChange: (units: string[]) => void
}

function UnitFilterSelect({ guests, onChange }: UnitFilterSelectProps) {
  const { t } = useTranslation(['common'])
  const [selectedUnits, setSelectedUnits] = useState<string[]>([])
  const units: { [key: string]: string } = {}
  guests.forEach((guest) =>
    guest.roles.forEach((role) => {
      if (!(role.ou_id in units)) {
        units[role.ou_id.toString()] = getRoleOuName(role)
      }
    })
  )
  const sortedUnitsArray = Object.keys(units).sort((a, b) =>
    units[a].toLowerCase().localeCompare(units[b].toLowerCase())
  )

  useEffect(() => {
    onChange(selectedUnits)
  }, [selectedUnits, onChange])

  const handleChange = (event: any, value: any) => {
    setSelectedUnits(typeof value === 'string' ? value.split(',') : value)
  }

  if (units) {
    return (
      <div>
        <FormControl sx={{ m: 1, width: 500 }}>
          <Stack spacing={5} sx={{ width: 475 }}>
            <Autocomplete
              multiple
              id="ou-select-label"
              options={sortedUnitsArray}
              getOptionLabel={(option) => units[option]}
              onChange={handleChange}
              renderInput={(param) => (
                <TextField {...param} label={t('chooseUnits')} />
              )}
            />
          </Stack>
        </FormControl>
      </div>
    )
  }
  return null
}

function ActiveGuests({ persons }: GuestProps) {
  const [activeExpanded, setActiveExpanded] = useState<boolean>(false)
  const [searchGuests, setSearchGuests] = useState<Guest[]>([])
  const [selectedUnits, setSelectedUnits] = useState<string[]>([])
  const [searchInput, setSearchInput] = useState<string>('')
  const [searching, setSearching] = useState<boolean>(false)
  const initialHideInactiveGuests = localStorage.getItem('hideInactiveGuests')
  const [hideInactiveGuests, setHideInactiveGuests] = useState<boolean>(
    initialHideInactiveGuests === null
      ? false
      : JSON.parse(initialHideInactiveGuests)
  )
  const [filteredInactiveGuests, setFilteredInactiveGuests] = useState<Guest[]>(
    []
  )

  const [t] = useTranslation(['common'])

  // Show all verified guests
  let guests = persons.length > 0 ? persons : []
  if (guests.length > 0) {
    guests = guests.filter((person) => person.verified)
  }

  // Wait 1s after last change to start the search.
  useEffect(() => {
    if (searchInput === '') {
      if (searchGuests) {
        setSearchGuests([])
      }
      return () => {}
    }
    setSearching(true)
    const delaySearch = setTimeout(() => {
      setSearchGuests(
        guests.filter((guest) =>
          `${guest.first.toLowerCase()} ${guest.last.toLowerCase()}`.includes(
            searchInput
          )
        )
      )
      setSearching(false)
    }, 1000)
    return () => clearTimeout(delaySearch)
  }, [searchInput])

  useEffect(() => {
    if (!hideInactiveGuests) {
      return () => setFilteredInactiveGuests([])
    }
    const guestsCopy =
      searchGuests.length > 0 ? _.cloneDeep(searchGuests) : _.cloneDeep(guests)
    // eslint-disable-next-line no-restricted-syntax
    for (const guest of guestsCopy) {
      guest.roles = guest.roles.filter(
        (role) => calculateStatus(guest, role)[0] !== 'expired'
      )
    }
    setFilteredInactiveGuests(guestsCopy)
    return () => {}
  }, [hideInactiveGuests, searchGuests])

  const getSponsorGuests = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setSearchInput(event.target.value.toLowerCase())
    } else {
      setSearchInput('')
    }
  }

  const FilterInactiveGuestsCheckbox = ({
    isChecked,
    onChange,
  }: FilterInactiveGuestsCheckboxProps) => (
    <div>
      <Typography
        variant="body1"
        sx={{ marginTop: '.5rem', marginBottom: '.5rem' }}
      >
        <Checkbox checked={isChecked} onChange={onChange} />
        {t('common:filterGuests')}
      </Typography>
    </div>
  )

  const getConfirmedGuests = () => {
    if (hideInactiveGuests) {
      return filteredInactiveGuests
    }
    if (searchInput) {
      return searchGuests
    }
    return guests
  }

  return (
    <StyledAccordion
      expanded={activeExpanded}
      onChange={() => {
        setActiveExpanded(!activeExpanded)
      }}
    >
      <StyledAccordionSummary
        expandIcon={<ArrowUpwardIcon sx={{ fill: 'black' }} />}
      >
        <Typography variant="h2">
          {`${t('common:activeGuests')} (${guests.length})`}
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body1"
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          {t('common:activeGuestsDescription')}
        </Typography>
        <Box
          sx={{
            marginBottom: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <FormControl sx={{ m: 1, width: 300 }}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder={t('placeholder')}
                onChange={getSponsorGuests}
              />
              <FilterInactiveGuestsCheckbox
                isChecked={hideInactiveGuests}
                onChange={() => {
                  setHideInactiveGuests(!hideInactiveGuests)
                  localStorage.setItem(
                    'hideInactiveGuests',
                    (!hideInactiveGuests).toString()
                  )
                }}
              />
            </FormControl>
            <UnitFilterSelect
              guests={guests}
              onChange={(units: string[]) => setSelectedUnits(units)}
            />
          </Box>
          {!searching ? (
            <GuestTable
              guests={getConfirmedGuests()}
              emptyText={
                searchInput
                  ? t('common:foundNoGuests')
                  : t('common:noActiveGuests')
              }
              marginWidth={1000}
              unitFilters={selectedUnits}
            />
          ) : (
            <Loading />
          )}
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  )
}

function WaitingGuests({ persons }: GuestProps) {
  const [waitingExpanded, setWaitingExpanded] = useState(false)

  // Show guests that have completed the registration but are not verified yet
  let guests = persons.length > 0 ? persons : []
  if (guests.length > 0) {
    guests = guests.filter((person) => person.registered && !person.verified)
  }
  const [t] = useTranslation(['common'])

  return (
    <StyledAccordion
      expanded={waitingExpanded}
      onChange={() => {
        setWaitingExpanded(!waitingExpanded)
      }}
    >
      <StyledAccordionSummary
        expandIcon={<ArrowUpwardIcon sx={{ fill: 'black' }} />}
      >
        <Typography variant="h2">
          {`${t('common:waitingGuests')} (${guests.length})`}{' '}
          {guests.length > 0 && (
            <NotificationsActiveIcon
              sx={{ color: 'error.main', fontSize: '1.625rem' }}
            />
          )}
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body1"
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          <Trans i18nKey="common:waitingGuestsDescription">
            Her godkjenner du gjester som har <b>registrert seg manuelt</b>.
            Gjester som har FEIDE-bruker trenger ikke godkjenning.
          </Trans>
        </Typography>
        <GuestTable
          guests={guests}
          emptyText={t('common:noWaitingGuests')}
          marginWidth={650}
          unitFilters={[]}
        />
      </AccordionDetails>
    </StyledAccordion>
  )
}

function FrontPage({ guests, loading }: FrontPageProps) {
  return (
    <Page pageWidth>
      <SponsorGuestButtons yourGuestsActive />
      <DownloadGuestsButton guests={guests}/>

      {loading ? (
        <Loading />
      ) : (
        <>
          <InvitedGuests persons={guests} />
          <br />
          <WaitingGuests persons={guests} />
          <br />
          <ActiveGuests persons={guests} />
        </>
      )}
    </Page>
  )
}

export default FrontPage

import { setCookie } from 'utils'
import { useUserContext } from 'contexts'
import { useLocation, Navigate } from 'react-router-dom'

const isAuthenticated = () => {
  const { user } = useUserContext()

  if (!user.auth) {
    const location = useLocation()
    setCookie('redirect', location.pathname)
    return false
  }
  return true
}

const RequireAuth = ({ children }: { children: JSX.Element }) =>
  isAuthenticated() ? children : <Navigate to="/" />

export default RequireAuth

import { Alert, AlertTitle } from '@mui/material'
import React from 'react'

export type WarningBoxData = {
  heading: string
  bodyText: string
}

export default function WarningBox({ heading, bodyText }: WarningBoxData) {
  return (
    <Alert severity="warning">
      <AlertTitle>{heading}</AlertTitle>
      <br />
      {bodyText}
    </Alert>
  )
}

import { Box, CircularProgress } from '@mui/material'

import { useUserContext } from 'contexts'
import LandingPage from 'routes/landing'
import LoginPage from './components/loginpage'

export default function FrontPage() {
  const { user } = useUserContext()

  if (user.auth) {
    return <LandingPage />
  }

  if (!user.fetched) {
    return (
      <Box
        sx={{
          margin: 'auto',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return <LoginPage />
}

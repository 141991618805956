import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ConfirmDialogProps = {
  title: string
  open: boolean
  setOpen: (value: boolean) => void
  onConfirm: () => void
  children: React.ReactNode
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { title, children, open, setOpen, onConfirm } = props
  const [t] = useTranslation('common')
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false)
            onConfirm()
          }}
          color="secondary"
        >
          {t('button.yes')}
        </Button>
        <Button onClick={() => setOpen(false)}>{t('button.no')}</Button>
      </DialogActions>
    </Dialog>
  )
}
export default ConfirmDialog

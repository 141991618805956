import { useTranslation } from 'react-i18next'

import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

import format from 'date-fns/format'
import i18n from 'i18next'
import { RegisterFormData } from './formData'
import useOus from '../../../hooks/useOus'
import useRoleTypes from '../../../hooks/useRoleTypes'

interface StepSummaryProperties {
  formData: RegisterFormData
}

function StepSummary(props: StepSummaryProperties) {
  const { t } = useTranslation(['common'])
  const { formData } = props
  const { ous } = useOus()
  const roleTypes = useRoleTypes()

  const selectedOus =
    ous === undefined
      ? undefined
      : ous.find((value) => value.id === formData.ou_id)
  const ousName =
    selectedOus === undefined
      ? ''
      : `${i18n.language === 'en' ? selectedOus.en : selectedOus.nb} (${
          selectedOus.orgreg_id
        })`

  // TODO Fix this confusing mix between use of id and identifier
  const selectedRoleType =
    roleTypes === undefined
      ? undefined
      : roleTypes.find(
          (value) =>
            value.id ===
            (formData.role_type === undefined ? -1 : formData.role_type)
        )
  const roleTypeName =
    selectedRoleType === undefined
      ? ''
      : `${
          i18n.language === 'en'
            ? selectedRoleType.name_en
            : selectedRoleType.name_nb
        }`

  return (
    <>
      <Box
        sx={{
          paddingTop: '1rem',
          paddingBottom: '1rem',
        }}
      >
        {t('registerWizardText.summaryContactInformation')}
      </Box>

      <Box sx={{ typography: 'body2' }}>
        {t('registerWizardText.summaryPage')}
      </Box>
      <Box sx={{ marginTop: '1rem', maxWidth: '50rem' }}>
        <Box
          sx={{
            typography: 'subtitle2',
            marginBottom: '0.5rem',
            paddingLeft: '0.5rem',
            bgcolor: 'secondary.light',
          }}
        >
          {t('registerWizardText.contactInformation')}
        </Box>

        <Grid sx={{ typography: 'body2', paddingLeft: '0.5rem' }} container>
          <Grid item xs={4}>
            {t('input.fullName')}
          </Grid>
          <Grid item xs={8}>
            {formData.first_name} {formData.last_name}
          </Grid>
          <Grid item xs={4}>
            {t('input.email')}
          </Grid>
          <Grid item xs={8}>
            {formData.email}
          </Grid>
        </Grid>

        <Box
          sx={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          {t('registerWizardText.summaryPeriod')}
        </Box>

        <Table>
          <TableHead
            sx={{
              paddingLeft: '0.5rem',
              bgcolor: 'secondary.light',
            }}
          >
            <TableRow>
              <TableCell>{t('registerWizardText.guestRole')}</TableCell>
              <TableCell>{t('registerWizardText.guestPeriod')}</TableCell>
              <TableCell>{t('registerWizardText.guestDepartment')}</TableCell>
              <TableCell>{t('input.comment')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{roleTypeName}</TableCell>
              <TableCell>
                {formData.role_start === null
                  ? null
                  : format(formData.role_start as Date, 'yyyy-MM-dd')}{' '}
                -{' '}
                {formData.role_end === null
                  ? null
                  : format(formData.role_end as Date, 'yyyy-MM-dd')}
              </TableCell>
              <TableCell>{ousName}</TableCell>
              <TableCell>{formData.comment}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  )
}

export default StepSummary

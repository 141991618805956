import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Page from 'components/page'
import { Container, Button, Typography } from '@mui/material'

const ConfirmGuestStep = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const inviteId = window.location.hash.slice(1).trim()

  return (
    <Page>
      <Container
        sx={{
          marginTop: '4rem',
          marginBottom: '2rem',
        }}
      >
        <Typography variant="h1" sx={{ paddingBottom: '1rem' }}>
          {t('header.applicationTitle')}
        </Typography>
      </Container>
      <Container
        sx={{
          marginBottom: '3rem',
        }}
      >
        <Typography>{t('confirmGuestDialog.info')}</Typography>
      </Container>
      <Container
        sx={{
          marginTop: '4rem',
          marginBottom: '3rem',
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <Button
          onClick={() => {
            navigate(`/complete-registration/#${inviteId}`)
          }}
          sx={{
            marginLeft: '3rem',
          }}
        >
          {t('button.continue')}
        </Button>
        <Button
          onClick={() => {
            navigate('/')
          }}
        >
          {t('button.cancel')}
        </Button>
      </Container>
    </Page>
  )
}

export default ConfirmGuestStep

import { Consent } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { TableCell, TableRow } from 'components/table'

interface UserConsentLineProps {
  consent: Consent
}
const UserConsentLine = ({ consent }: UserConsentLineProps) => {
  const [, i18n] = useTranslation('common')
  let name = ''
  let choice = ''
  switch (i18n.language) {
    case 'nb':
      name =
        consent.type.name_nb || consent.type.name_nn || consent.type.name_en
      choice =
        consent.choice.text_nb ||
        consent.choice.text_nn ||
        consent.choice.text_en
      break
    case 'nn':
      name =
        consent.type.name_nn || consent.type.name_nb || consent.type.name_en
      choice =
        consent.choice.text_nn ||
        consent.choice.text_nb ||
        consent.choice.text_en
      break
    // en
    default:
      name =
        consent.type.name_en || consent.type.name_nb || consent.type.name_nn
      choice =
        consent.choice.text_en ||
        consent.choice.text_nb ||
        consent.choice.text_nn
      break
  }
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align="left">{name}</TableCell>
      <TableCell component="th" scope="row">
        {consent.consent_given_at
          ? format(consent.consent_given_at, 'yyyy-MM-dd')
          : null}
      </TableCell>
      <TableCell align="left">{choice}</TableCell>
    </TableRow>
  )
}
export default UserConsentLine

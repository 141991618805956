import React from 'react'
import Page from 'components/page'
import { Typography } from '@mui/material'

export default function NotFound() {
  return (
    <Page>
      <Typography variant="h1">404 - Not found</Typography>
    </Page>
  )
}

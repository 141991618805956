import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { appInst } from 'appConfig'

function LanguageSelector({ noPadding }: { noPadding: boolean }) {
  const { i18n, t } = useTranslation('common')
  const [selectedLanguage, setSelectedLanguage] = useState('en')

  const languageOptions = new Map([
    ['en', 'English'],
    ['nn', 'Nynorsk'],
    ['nb', 'Bokmål'],
  ])

  useEffect(() => {
    setSelectedLanguage(i18n.language)
  })

  const handleLanguageChange = (event: SelectChangeEvent) => {
    if (event.target) {
      i18n.changeLanguage(event.target.value)
    }
  }

  const sx: Record<string, any> = {
    color: 'white',
    '& .MuiSelect-icon': {
      color: 'white',
    },
    fontSize: '1.25rem',
  }

  if (noPadding) {
    sx['& .MuiSelect-select'] = { paddingBottom: '0rem' }
  }

  const sxNTNU: Record<string, any> = {
    color: 'black',
    '& .MuiSelect-icon': {
      color: 'black',
    },
    fontSize: '1.25rem',
  }

  if (noPadding) {
    sx['& .MuiSelect-select'] = { paddingBottom: '0rem' }
  }

  return (
    <Select
      labelId="language-select"
      label={t('header.selectLanguage')}
      defaultValue={selectedLanguage}
      renderValue={() => (i18n.language !== 'en' ? 'Language' : 'Språk')}
      sx={() => (appInst === 'ntnu' ? sxNTNU : sx)}
      variant="standard"
      value={selectedLanguage}
      onChange={handleLanguageChange}
    >
      {Array.from(languageOptions.entries()).map((entry) => (
        <MenuItem key={entry[0]} value={entry[0]}>
          {entry[1]}
        </MenuItem>
      ))}
    </Select>
  )
}

export default LanguageSelector

import { createContext, useContext } from 'react'

export interface IFeatureContext {
  // Controls whether the contact person at unit field is shown in the register new guest wizard
  displayContactAtUnit: boolean
  // Controls whether the optional field is shown in the register new guest wizard
  displayComment: boolean
  // Should the contact at unit field be shown for the guest when he registers his information?
  displayContactAtUnitGuestInput: boolean
  // Controls whether the gender field is shown for guests
  displayGenderFieldForGuest: boolean
}

export const FeatureContext = createContext<IFeatureContext>({
  displayContactAtUnit: true,
  displayComment: true,
  displayContactAtUnitGuestInput: true,
  displayGenderFieldForGuest: true,
})

export const useFeatureContext = () => useContext(FeatureContext)

import { styled } from '@mui/material/styles'
import { Box, Link, useMediaQuery } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { appInst } from 'appConfig'

import { useUserContext } from 'contexts'
import LanguageSelector from 'components/languageselector'
import UserInfo from 'routes/components/userInfo'
import { getHeaderLogo } from './logos'

const StyledHeader = styled('header')(({ theme }) => ({
  color: theme.greg.textWhite,
  backgroundColor: theme.palette.primary.main,
  ...(appInst === 'ntnu' && { backgroundColor: theme.palette.primary.light }),

  a: {
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
}))

const MainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 auto',
  maxWidth: theme.greg.appMaxWidth,
  padding: '1rem 2rem 1rem 2rem',
  alignItems: 'flex-end',
  '@media (max-width:700px)': { alignItems: 'center' },
}))

const LogoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
})

const Menu = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
})

const Header = () => {
  const { user } = useUserContext()
  const { t } = useTranslation('common')

  const logoutLink =
    user.auth_type === 'oidc' ? '/oidc/logout' : '/complete-registration/logout'
  const spaceAvailable = useMediaQuery('(min-width:600px)')

  return (
    <StyledHeader>
      <MainContainer>
        <LogoContainer>
          <Link component={RouterLink} to="/">
            {getHeaderLogo()}
          </Link>
        </LogoContainer>
        <Menu>
          <LanguageSelector noPadding />
          {spaceAvailable && <UserInfo />}
          {user.auth && (
            <Box sx={{ paddingLeft: '1rem' }}>
              <Link
                sx={{
                  color: 'white',
                  fontSize: '1.25rem',
                  whiteSpace: 'nowrap',
                  ...(appInst === 'ntnu' && { color: 'black' }),
                }}
                href={logoutLink}
              >
                {t('button.logout')}
                <LogoutIcon
                  visibility={spaceAvailable ? 'visible' : 'hidden'}
                  fontSize="small"
                  sx={{
                    paddingLeft: '0.3rem',
                    verticalAlign: 'middle',
                  }}
                />
              </Link>
            </Box>
          )}
        </Menu>
      </MainContainer>
    </StyledHeader>
  )
}

export default Header

import { ThemeOptions } from '@mui/material'

const uioTheme: ThemeOptions = {
  greg: {
    footerLinkBgColor: '#202020',
    wizardButtonColor: 'black',
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#01579B',
      dark: '#1565c0',
      light: '#A4C8E4',
    },
  },
}

export default uioTheme

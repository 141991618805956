import { FeatureContext } from 'contexts'
import React from 'react'
import {
  displayContactAtUnit,
  displayComment,
  displayContactAtUnitGuestInput,
  displayGenderFieldForGuest,
} from '../appConfig'
import { IFeatureContext } from '../contexts/featureContext'

type FeatureProviderProps = {
  children: React.ReactNode
}

function FeatureProvider(props: FeatureProviderProps) {
  const { children } = props
  const features: IFeatureContext = {
    displayContactAtUnit,
    displayComment,
    displayContactAtUnitGuestInput,
    displayGenderFieldForGuest,
  }

  return (
    <FeatureContext.Provider value={features}>
      {children}
    </FeatureContext.Provider>
  )
}

export default FeatureProvider

import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'

import uibTheme from 'themes/uib'
import uioTheme from 'themes/uio'
import uitTheme from 'themes/uit'
import ntnuTheme from 'themes/ntnu'
import mainTheme from 'themes/main'

import { appInst } from 'appConfig'

declare module '@mui/material/styles' {
  interface Theme {
    greg: {
      appMinWidth: string
      appMaxWidth: string
      deactivatedColor: string
      textWhite: string
      footerLinkBgColor: string
      wizardButtonColor: string
    }
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    greg?: {
      appMinWidth?: string
      appMaxWidth?: string
      deactivatedColor?: string
      textWhite?: string
      footerLinkBgColor?: string
      wizardButtonColor?: string
    }
  }
}

export const defaultTheme = createTheme(mainTheme)

function getTheme() {
  switch (appInst) {
    case 'uib':
      return createTheme(deepmerge(mainTheme, uibTheme))
    case 'uio':
      return createTheme(deepmerge(mainTheme, uioTheme))
    case 'uit':
      return createTheme(deepmerge(mainTheme, uitTheme))
    case 'ntnu':
      return createTheme(deepmerge(mainTheme, ntnuTheme))
    default:
      return defaultTheme
  }
}

export default getTheme

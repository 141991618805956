import useGuest from 'hooks/useGuest'
import { Route, Routes, useParams } from 'react-router-dom'
import { submitJsonOpts } from 'utils'
import GuestInfo from './guestInfo'
import GuestRoleInfo from './guestRoleInfo'
import NewGuestRole from './newGuestRole'

type GuestInfoParams = {
  pid: string
}

type GuestRoutesProps = {
  reloadGuests: () => void
}

function GuestRoutes({ reloadGuests }: GuestRoutesProps) {
  const { pid } = useParams<GuestInfoParams>()
  if (typeof pid === 'undefined') {
    return <></>
  }
  const { guestInfo, reloadGuestInfo } = useGuest(pid)

  const updateField =
    (fieldName: string) =>
    (validFieldValue: string, errorCallback: (error: any) => void) => {
      fetch(
        `/api/ui/v1/person/${pid}`,
        submitJsonOpts('PATCH', { [fieldName]: validFieldValue })
      )
        .then((res) => {
          if (res.ok) {
            // Just reload data from the server, it will cause the children
            // to be rerendered
            reloadGuestInfo()
          } else {
            errorCallback(res)
          }
        })
        .catch((error) => {
          errorCallback(error)
        })
    }

  const updateEmail = updateField('email')

  const updateMobilePhone = updateField('mobile')

  // Resending the invitation does not cause a change in the state, so nothing needs to be updated after the call
  const resend = () => {
    fetch(`/api/ui/v1/invite/${pid}/resend`, submitJsonOpts('PATCH', {}))
      .then((res) => {
        if (!res.ok) {
          return null
        }
        return res.text()
      })
      .then((result) => {
        if (result !== null) {
          console.log('result', result)
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  return (
    <Routes>
      <Route
        path="/roles/:id"
        element={
          <GuestRoleInfo
            guest={guestInfo}
            reloadGuest={reloadGuestInfo}
            reloadGuests={reloadGuests}
          />
        }
      />
      <Route
        path="/newrole"
        element={
          <NewGuestRole guest={guestInfo} reloadGuestInfo={reloadGuestInfo} />
        }
      />
      <Route
        path=""
        element={
          <GuestInfo
            guest={guestInfo}
            updateEmail={updateEmail}
            updateMobilePhone={updateMobilePhone}
            resend={resend}
            reloadGuests={reloadGuests}
            reloadGuest={reloadGuestInfo}
          />
        }
      />
    </Routes>
  )
}

export default GuestRoutes

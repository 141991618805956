declare global {
  /* tslint:disable */
  interface Window {
    ENV: any
  }
}

/* Locate the client environment */
const isProduction = process.env.NODE_ENV === 'production'
const env = isProduction ? window.ENV : process.env

/* General settings */
export const appTimezone: string = 'Europe/Oslo'

/* Version */
export const appVersion: string = process.env.REACT_APP_VERSION as string
export const appName: string = process.env.REACT_APP_NAME as string

/* Institution (used for theming) */
export const appInst: string = env.REACT_APP_INST as string

/* Link to read more about the importance of verifying a guest */
export const verifyGuestExplanationLink: string =
  env.REACT_APP_VERIFY_GUEST_EXPLANATION_LINK as string

/* Footer content */
export const responsibleOrganization: string =
  env.REACT_APP_RESPONSIBLE_ORGANIZATION as string
export const responsibleOrganizationEn: string =
  env.REACT_APP_RESPONSIBLE_ORGANIZATION_EN as string

/* no links */
export const accessibilityStatementLink: string = 
  env.REACT_APP_ACCESSIBILITY_STATEMENT_LINK as string
export const itRulesLink: string = env.REACT_APP_IT_RULES_LINK as string
export const privacyPolicyLink: string =
  env.REACT_APP_PRIVACY_POLICY_LINK as string
export const technicalSupportLink: string = env.REACT_APP_SUPPORT_URL as string
export const documentationLink: string =
  env.REACT_APP_DOCUMENTATION_LINK as string
export const responsibleOrganizationLink: string =
  env.REACT_APP_RESPONSIBLE_ORGANIZATION_LINK as string

/* en links */
export const itRulesLinkEn: string = env.REACT_APP_IT_RULES_LINK_EN as string
export const privacyPolicyLinkEn: string =
  env.REACT_APP_PRIVACY_POLICY_LINK_EN as string
export const technicalSupportLinkEn: string =
  env.REACT_APP_SUPPORT_URL_EN as string
export const documentationLinkEn: string =
  env.REACT_APP_DOCUMENTATION_LINK_EN as string
export const responsibleOrganizationLinkEn: string =
  env.REACT_APP_RESPONSIBLE_ORGANIZATION_LINK_EN as string

/* Sentry */
const sentryPublicKey: string = env.REACT_APP_SENTRY_PUBLIC_KEY as string
const sentryProjectId: string = env.REACT_APP_SENTRY_PROJECT_ID as string
const sentryHost: string = env.REACT_APP_SENTRY_HOST as string
export const sentryEnabled: boolean = sentryHost !== undefined
export const sentryDsn: string = `https://${sentryPublicKey}@${sentryHost}/${sentryProjectId}`
export const sentryEnvironment: string = env.REACT_APP_SENTRY_ENVIRONMENT as string

/* Feature toggles */
export const displayContactAtUnit: boolean =
  env.REACT_APP_DISPLAYCONTACTATUNIT === 'true'
export const displayComment: boolean = env.REACT_APP_DISPLAYCOMMENT === 'true'
export const displayContactAtUnitGuestInput: boolean =
  env.REACT_APP_DISPLAYCONTACTATUNITGUESTINPUT === 'true'
export const displayGenderFieldForGuest: boolean =
  env.REACT_APP_DISPLAYGENDERFIELDFORGUEST === 'true'

/* Show warning in the footer about this being a staging/test system */
export const appStagingWarning: boolean =
  env.REACT_APP_STAGING_WARNING === 'true'

/* Is there a consent step during guest registration? */
export const guestConsentStepEnabled: boolean =
  env.REACT_APP_GUEST_CONSENT_STEP_ENABLED === 'true'

/* Should the 'available in search' field on roles be available for use? */
export const availableInSearchEnabled: boolean =
  env.REACT_APP_AVAILABLE_IN_SEARCH_ENABLED === 'true'

/* Should nin verification be disabled for sponsors? */
export const disableNinVerification: boolean =
  env.REACT_APP_DISABLE_NIN_VERIFICATION === 'true'

/* Can national id card number be used as identification to verify guests? */
export const enableNationalIdCardNumber: boolean =
  env.REACT_APP_ENABLE_NATIONAL_ID_CARD_NUMBER === 'true'

/* Should iga be checked when verifying identificators? */
export const enableIgaCheck: boolean = env.REACT_APP_ENABLE_IGA_CHECK === 'true'

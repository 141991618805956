import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { Box, CircularProgress } from '@mui/material'

import { useUserContext } from 'contexts'

export default function LogoutInviteSession() {
  // Fetch backend endpoint to preserve invite_id in backend session then redirect
  const [, , removeCookie] = useCookies(['sessionid'])
  const [loggedOut, setLoggedOut] = useState(false)
  const { fetchUserInfo } = useUserContext()

  useEffect(() => {
    fetch('/api/ui/v1/invitecheck/', { method: 'DELETE' })
      .then(() => removeCookie('sessionid'))
      .then(() => fetchUserInfo())
      .then(() => setLoggedOut(true))
  }, [])

  if (loggedOut) {
    return <Navigate to="/" />
  }

  return (
    <Box sx={{ margin: 'auto' }}>
      <CircularProgress />
    </Box>
  )
}

import { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import { styled } from '@mui/system'
import { CssBaseline } from '@mui/material'
import fetchIntercept from 'fetch-intercept'

import { registerLocale } from 'i18n-iso-countries'
import i18n_iso_countries_en from 'i18n-iso-countries/langs/en.json'
import i18n_iso_countries_nb from 'i18n-iso-countries/langs/nb.json'
import i18n_iso_countries_nn from 'i18n-iso-countries/langs/nn.json'

import { useUserContext } from 'contexts'
import { getCookie, deleteCookie } from 'utils'

import GuestRegister from 'routes/guest/register'
import Sponsor from 'routes/sponsor'
import Register from 'routes/sponsor/register'
import FrontPage from 'routes/frontpage'
import Invite from 'routes/invite'
import LogoutInviteSession from 'routes/invite/logout'
import Footer from 'routes/components/footer'
import Header from 'routes/components/header'
import NotFound from 'routes/components/notFound'
import RequireAuth from 'components/requireAuth'
import ConfirmGuestStep from 'routes/guest/register/steps/confirmation'

const AppWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '1.6rem',
  minHeight: '100vh',
  marginTop: 'auto',
})

export default function App() {
  const { user, clearUserInfo } = useUserContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (user.auth) {
      const redirect = getCookie('redirect')
      if (redirect) {
        deleteCookie('redirect')
        navigate(redirect)
      }
    }
  }, [user.fetched])

  // Load country names for the supported languages
  registerLocale(i18n_iso_countries_en)
  registerLocale(i18n_iso_countries_nb)
  registerLocale(i18n_iso_countries_nn)

  // Intercept fetch responses
  fetchIntercept.register({
    response(response) {
      if (user.auth && response.status === 401) {
        // Clear userInfo, if we get a 401 from the backend
        clearUserInfo()
      }
      return response
    },
  })

  return (
    <>
      <CssBaseline />
      <AppWrapper>
        <Header />
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route
            path="/sponsor/*"
            element={
              <RequireAuth>
                <Sponsor />
              </RequireAuth>
            }
          />

          <Route
            path="/register/*"
            element={
              <RequireAuth>
                <Register />
              </RequireAuth>
            }
          />

          <Route
            path="/complete-registration/logout"
            element={<LogoutInviteSession />}
          />
          <Route path="/complete-registration/" element={<Invite />} />
          <Route path="/guestregister" element={<GuestRegister />} />
          <Route path="/invite/" element={<ConfirmGuestStep />} />
          <Route element={<NotFound />} />
        </Routes>
        <Footer />
      </AppWrapper>
    </>
  )
}

import { useState, useEffect } from 'react'
import { fetchJsonOpts } from 'utils'

type OuData = {
  id: number
  nb: string
  en: string
  orgreg_id?: string
  identifier_1?: string
  identifier_2?: string
  acronym_nob?: string
  acronym_nno?: string
  acronym_eng?: string
}

function useOus(): { ous: OuData[] | undefined; loading: boolean } {
  const [ous, setOus] = useState<OuData[] | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const getOptions = async () => {
    const response = await fetch('/api/ui/v1/ous', fetchJsonOpts())
    if (response.ok) {
      const ousJson = await response.json()
      setOus(ousJson)
    } else {
      setOus([])
    }
    setLoading(false)
  }

  useEffect(() => {
    getOptions()
  }, [])
  return { ous, loading }
}

export const enSort = (a: OuData, b: OuData) => {
  if (a.en > b.en) {
    return 1
  }
  if (b.en > a.en) {
    return -1
  }
  return 0
}

export const nbSort = (a: OuData, b: OuData) => {
  if (a.nb > b.nb) {
    return 1
  }
  if (b.nb > a.nb) {
    return -1
  }
  return 0
}

export type { OuData }
export default useOus

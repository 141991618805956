import {
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import Page from 'components/page'
import { Link, useNavigate } from 'react-router-dom'
import SponsorGuestButtons from 'routes/components/sponsorGuestButtons'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Box, styled } from '@mui/system'
import { fetchJsonOpts } from 'utils'

type Guest = {
  first: string
  last: string
  date_of_birth: string
  pid: string
  value: string
}

const StyledSpan = styled('span')({
  color: 'red',
})

function FrontPage() {
  const [t] = useTranslation('common')
  const [guests, setGuests] = useState<Guest[]>([])
  const [searchHasInput, setSearchHasInput] = useState(false)
  const navigateToRow = useNavigate()

  const getGuests = (event: any) => {
    if (event.target.value) {
      setSearchHasInput(true)
      fetch(
        `/api/ui/v1/person/search/?q=${event.target.value}`,
        fetchJsonOpts()
      )
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          setSearchHasInput(false)
          return []
        })
        .then((responseJson) => {
          setGuests(responseJson)
        })
        .catch(() => {})
    } else {
      setSearchHasInput(false)
      setGuests([])
    }
  }

  const tableGuestHead = () => (
    <TableHead>
      <TableRow>
        <TableCell>
          <b>{t('input.fullName')}</b>
        </TableCell>
        <TableCell>
          <b>{t('input.dateOfBirth')}</b>
        </TableCell>
      </TableRow>
    </TableHead>
  )

  const tableGuestRow = (guest: Guest) => {
    const guestTo = `/sponsor/guest/${guest.pid}`
    return (
      <TableRow
        key={`${guest.pid}-${guest.date_of_birth}`}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
        onClick={() => navigateToRow(guestTo)}
      >
        <TableCell>
          <Link
            to={guestTo}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {`${guest.first  } ${  guest.last}`}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            to={guestTo}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {guest.date_of_birth}
          </Link>
        </TableCell>
      </TableRow>
    )
  }

  const tableGuestButton = () => (
    <Button
      sx={{ marginTop: '1rem', marginBottom: '1rem' }}
      variant="contained"
      color="secondary"
      component={Link}
      to="/register/new"
    >
      {t('register.registerButtonText')}
    </Button>
  )

  const displayTableOrError = () => {
    if (guests.length > 0) {
      return (
        <TableContainer>
          <Table>
            {tableGuestHead()}
            <TableBody>
              {guests.map((guest) => tableGuestRow(guest))}
              <TableRow>
                <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                  {tableGuestButton()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )
    } if (guests.length === 0 && searchHasInput) {
      return (
        <div>
          <StyledSpan>{t('register.noResults')}</StyledSpan>
          <div style={{ width: '100%', textAlign: 'center' }}>
            {tableGuestButton()}
          </div>
        </div>
      )
    } 
      return null
  }

  return (
    <Page>
      <SponsorGuestButtons registerNewGuestActive />
      <Typography variant="h2" sx={{ marginBottom: '1rem' }}>
        {t('register.registerHeading')}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
        {t('register.registerText')}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        <TextField
          variant="standard"
          fullWidth
          onChange={getGuests}
          placeholder={t('register.placeHolder')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
      >
        {displayTableOrError()}
      </Box>
    </Page>
  )
}
export default FrontPage

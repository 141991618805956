import { Box, Typography } from '@mui/material'
import { appInst } from 'appConfig'
import { useUserContext } from 'contexts'

function UserInfo() {
  const { user } = useUserContext()

  if (user.auth) {
    return (
      <Box sx={{ paddingLeft: '0.5rem' }}>
        <Typography
          sx={{
            fontSize: '1.25rem',
            ...(appInst === 'ntnu' && { color: 'black' }),
          }}
        >
          {user.first_name} {user.last_name}
        </Typography>
      </Box>
    )
  }
  return <></>
}

export default UserInfo

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      no: ['nb'],
      'en-US': ['en'],
      default: ['nb'],
    },
    supportedLngs: ['nb', 'en', 'nn'],
    debug: process.env.NODE_ENV !== 'production',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false,
    },
    // react: {
    //   useSuspense: false,
    // },
    detection: {
      order: ['querystring', 'cookie'],
      caches: ['cookie'],
      lookupQuerystring: 'lang',
    },
  })

export default i18n

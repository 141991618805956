import { format } from 'date-fns'
import { Guest, Role } from 'interfaces'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup
} from '@mui/material'
import { useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'

interface GuestProps {
  guests: Guest[]
}

interface GuestDataProps {
  guests: Guest[]
  individualChecked: {
    invitedGuests: boolean,
    waitingGuests: boolean,
    activeGuests: boolean,
    onlyActiveStatusGuests: boolean,
    allActiveGuests: boolean,
  }
}

function guestData({ guests, individualChecked}: GuestDataProps){
  const { i18n } = useTranslation()
  let header = []
  if (i18n.language === 'en') {
    header = ["Name", "Guest Role", "Unit", "Start date", "End date", "Host", "Comments"]
  } else {
    header = ["Navn", "Gjesterolle", "Avdeling", "Startdato", "Sluttdato", "Vert", "Kommentarer"]
  }
  const sortGuests = (a: Guest, b: Guest) => {
    if (a.first === b.first)
      return a.last.localeCompare(b.last)
    return a.first.localeCompare(b.first)
  }
  const invitedGuests = guests.filter((person) => !person.registered).sort(sortGuests)
  const waitingGuests = guests.filter((person) => person.registered && !person.verified).sort(sortGuests)
  const activeGuests = guests.filter((person) => person.verified).sort(sortGuests)
  
  const guestList = []
  if (individualChecked.invitedGuests) {
    guestList.push(...invitedGuests)
  }
  if (individualChecked.waitingGuests) {
    guestList.push(...waitingGuests)
  }

  const today = new Date()
  const data = [header,
    guestList.map(
      (guest: Guest) => 
        guest.roles.map((role: Role) => 
            [
              `${guest.first} ${guest.last}`,
              i18n.language === 'en' ? role.name_en : role.name_nb,
              i18n.language === 'en' ? role.ou_en : role.ou_nb,
              role.start_date ? format(role.start_date, "yyyy-MM-dd") : null,
              format(role.end_date, "yyyy-MM-dd"),
              role.sponsor_name,
              role.comments,
            ]
          ).join("\n")
        ).join("\n")
    ]
  if (individualChecked.activeGuests) {
    let activeGuestList = []
    if (individualChecked.onlyActiveStatusGuests) {
      activeGuestList = [activeGuests.map((guest: Guest) => guest.roles.filter((role:Role) => role.end_date >= today).map((role:Role) => 
        [
          `${guest.first} ${guest.last}`,
          i18n.language === 'en' ? role.name_en : role.name_nb,
          i18n.language === 'en' ? role.ou_en : role.ou_nb,
          role.start_date ? format(role.start_date, "yyyy-MM-dd") : null,
          format(role.end_date, "yyyy-MM-dd"),
          role.sponsor_name,
          role.comments,
        ]
      ).join("\n")).join("\n").trim()]
    } else {
      activeGuestList = [activeGuests.map((guest: Guest) => guest.roles.map((role:Role) => 
        [
          `${guest.first} ${guest.last}`,
          i18n.language === 'en' ? role.name_en : role.name_nb,
          i18n.language === 'en' ? role.ou_en : role.ou_nb,
          role.start_date ? format(role.start_date, "yyyy-MM-dd") : null,
          format(role.end_date, "yyyy-MM-dd"),
          role.sponsor_name,
          role.comments,
        ]
      ).join("\n")).join("\n")]
    }
    data.push(...activeGuestList)
  }
  return data.join("\n").split("\n").filter(x=> x !== "").join("\n")
}


function DownloadGuestsButton({ guests }: GuestProps) {
  const initialState = {
    invitedGuests: false,
    waitingGuests: false,
    activeGuests: false,
    onlyActiveStatusGuests: false,
    allActiveGuests: false,
  }
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false)
  const [individualChecked, setIndividualChecked] = useState(initialState)
  const { t } = useTranslation('common')

  const boxesChecked = Object.values(individualChecked).some(value => value === true)
  const activeGuestChoiceChecked = !individualChecked.activeGuests || (individualChecked.activeGuests && (individualChecked.onlyActiveStatusGuests || individualChecked.allActiveGuests))

  const activeGuestsChecked = individualChecked.activeGuests

  const handleIndividualCheckedChange = (event: any, name: string) => {
    setIndividualChecked({
      ...individualChecked,
      [name]: event.target.checked,
    })
  }

  const openAndReset = () => {
    setOpenDialogBox(true)
    setIndividualChecked(initialState)
  }

  const makeFileName = () => {
    const { i18n } = useTranslation()
    const date = new Date()
    const dformat = [
      date.getFullYear(),
      (date.getUTCMonth() + 1).toString().padStart(2, "0"),
      date.getUTCDate().toString().padStart(2, "0")
    ].join("-")

    let title = i18n.language === "en" ? "guests" : "gjester"

    if (i18n.language === "en") {
      if (individualChecked.invitedGuests) {
        title += "_invited"
      }
      if (individualChecked.waitingGuests) {
        title += "_waiting"
      }
      if (individualChecked.allActiveGuests) {
        title += "_all_confirmed"
      }
      else if (individualChecked.activeGuests) {
        title += "_active"
      }
    } else {
      if (individualChecked.invitedGuests) {
        title += "_invitert"
      }
      if (individualChecked.waitingGuests) {
        title += "_venter"
      }
      if (individualChecked.allActiveGuests) {
        title += "_alle_godkjente"
      }
      else if (individualChecked.activeGuests) {
        title += "_aktive"
      }
    }

    return `${title}_${dformat}.csv`
    
  }

  return (
    <Box textAlign="center" sx={{ mb: '2rem' }}>
      <Button onClick={()=> openAndReset()} sx={{ borderColor: 'black', color: 'black' }}>
        {t('button.downloadGuestData')}
      </Button>

      <Dialog open={openDialogBox} onClose={()=>setOpenDialogBox(false)}>
        <DialogTitle>{t('download.title')}</DialogTitle>
        <DialogContent sx={{width: '30rem', height: '20rem'}}>
          <DialogContentText sx={{mb: '1rem'}}>{t('download.bodyText')}</DialogContentText>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={individualChecked.invitedGuests} onChange={(e) => handleIndividualCheckedChange(e, 'invitedGuests')} />}
              label={t('download.invitedGuests')}
            />
            <FormControlLabel
              control={<Checkbox checked={individualChecked.waitingGuests} onChange={(e) => handleIndividualCheckedChange(e, 'waitingGuests')} />}
              label={t('download.waitingGuests')}
            />
            <FormControlLabel
              control={<Checkbox checked={individualChecked.activeGuests} onChange={(e) => handleIndividualCheckedChange(e, 'activeGuests')} />}
              label={t('download.activeGuests')}
            />
            {activeGuestsChecked && (<Box sx={{display: "flex", flexDirection: "column"}}>
            <FormControlLabel sx={{ml: "2rem"}}
            control={<Checkbox checked={individualChecked.onlyActiveStatusGuests} onChange={(e) => handleIndividualCheckedChange(e, 'onlyActiveStatusGuests')} />}
            label={t('download.onlyActive')}
            />
            <FormControlLabel sx={{ml: "2rem"}}
          control={<Checkbox checked={individualChecked.allActiveGuests} onChange={(e) => handleIndividualCheckedChange(e, 'allActiveGuests')} />}
          label={t('download.allGuests')}
        />
        </Box>)}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenDialogBox(false)} sx={{mr: '1rem'}}>{t('button.cancel')}</Button>
          <CSVLink filename={makeFileName()} data={guestData({guests, individualChecked})}>
            <Button disabled={!boxesChecked || !activeGuestChoiceChecked} onClick={() => setOpenDialogBox(false)}>{t('button.download')}</Button>
          </CSVLink>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default DownloadGuestsButton

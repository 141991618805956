import { Route, Routes } from 'react-router-dom'
import FrontPage from './frontPage'
import StepRegistration from './stepRegistration'

function Register() {
  return (
    <Routes>
      <Route path="/new" element={<StepRegistration />} />
      <Route path="" element={<FrontPage />} />
    </Routes>
  )
}
export default Register

import {
  Alert,
  Button,
  Box,
  Table,
  TableBody,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import ServerErrorReport, {
  ServerErrorReportData,
} from 'components/errorReport'
import {
  TableContainer,
  TableHead,
  TableHeadCell,
  TableRow,
  TableCell,
} from 'components/table'
import Page from 'components/page'
import { UserRoleLine } from 'components/roleLine'
import UserConsentLine from 'components/consentLine'
import { useUserContext } from 'contexts'
import { FetchedRole, Role, Consent, FetchedConsent } from 'interfaces'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { enableNationalIdCardNumber } from 'appConfig'
import {
  isValidMobilePhoneNumber,
  parseRole,
  parseConsent,
  submitJsonOpts,
} from 'utils'

type MobilePhone = {
  mobilePhone: string
}

interface GetUserInfoProps {
  getUserInfo: () => void
}

const GuestPage = ({ getUserInfo }: GetUserInfoProps) => {
  const { user } = useUserContext()

  const [t] = useTranslation('common')
  const [roles, setRoles] = useState<Role[]>([])
  const [consents, setConsents] = useState<Consent[]>([])
  useEffect(() => {
    setRoles(user.roles.map((role: FetchedRole) => parseRole(role)))
    setConsents(user.consents.map((cons: FetchedConsent) => parseConsent(cons)))
  }, [])
  const [updateError, setUpdateError] = useState<ServerErrorReportData>()
  const [updateOKMsg, setUpdateOKMsg] = useState<string>('')

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<MobilePhone>({
    mode: 'onTouched',
    defaultValues: { mobilePhone: user.mobile_phone ?? '' },
  })

  const updateMobilePhone = (
    mobilePhone: string,
    errorCallback: (error: any) => void
  ) => {
    fetch(
      `/api/ui/v1/me/profile/update/`,
      submitJsonOpts('PATCH', { mobile: mobilePhone })
    )
      .then((res) => {
        if (res.ok) {
          getUserInfo()
        } else {
          errorCallback(res)
        }
      })
      .catch((error) => {
        errorCallback(error)
      })
  }

  // This function inspects the errorText and tries to
  // create an error report based on it by looking for
  // the code field, if that does not work a generic
  // error report is shown with the raw error text
  const createErrorMessageBasedOnText = (
    errorText: string,
    statusCode: number | undefined = undefined,
    statusText: string | undefined = undefined
  ) => {
    try {
      // Try to extract code from error text
      const errorAsJson = JSON.parse(errorText)
      if ('code' in errorAsJson) {
        setUpdateError({
          errorHeading: t('error.mobilePhoneUpdateFailed'),
          statusCode,
          statusText,
          errorBodyText: t(`error.codes.${errorAsJson.code}`),
        })
      }
    } catch (e) {
      // Use the text from the error directly if no code could be extracted
      setUpdateError({
        errorHeading: t('error.mobilePhoneUpdateFailed'),
        statusCode: undefined,
        statusText: undefined,
        errorBodyText: errorText,
      })
    }
  }

  const submit: SubmitHandler<MobilePhone> = (data) => {
    setUpdateError(undefined)
    updateMobilePhone(data.mobilePhone, (errorResponse: any) => {
      if (typeof errorResponse === 'string') {
        createErrorMessageBasedOnText(errorResponse)
      } else if (errorResponse instanceof Response) {
        // Some not successful response code
        errorResponse
          .text()
          .then((text: string) => {
            createErrorMessageBasedOnText(
              text,
              errorResponse.status,
              errorResponse.statusText
            )
          })
          .catch((error: any) => {
            // Not expected
            console.error(error)
          })
      } else {
        // Not a string in the error response, not expected
        setUpdateError({
          errorHeading: t('error.mobilePhoneUpdateFailed'),
          statusCode: undefined,
          statusText: undefined,
          errorBodyText: errorResponse,
        })
      }
    })
    if (!updateError) {
      setUpdateOKMsg('update.mobilePhone')
    }
  }

  useEffect(() => {
    reset({ mobilePhone: user.mobile_phone }) // Forces defaultValue to be set to the new value, making the form not dirty
    setValue('mobilePhone', user.mobile_phone)
  }, [user])

  return (
    <Page>
      {updateError !== undefined && (
        <Box sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <ServerErrorReport
            errorHeading={updateError?.errorHeading}
            statusCode={updateError?.statusCode}
            statusText={updateError?.statusText}
            errorBodyText={updateError?.errorBodyText}
          />
        </Box>
      )}
      {updateOKMsg !== '' && (
        <Alert
          severity="success"
          sx={{ marginBottom: '1rem' }}
          onClose={() => setUpdateOKMsg('')}
        >
          {t(updateOKMsg)}
        </Alert>
      )}
      <Typography variant="h2">{t('guestInfo.registeredInfo')}</Typography>
      <Typography
        variant="body1"
        sx={{ paddingBottom: '1rem', paddingTop: '1rem' }}
      >
        {t('guestInfo.registeredInfoExplanation')}
      </Typography>

      <Typography variant="h2">{t('guestInfo.contactInfo')}</Typography>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: 'none' }}>
            <TableRow>
              <TableHeadCell align="left">
                {t('guestInfo.contactInfo')}
              </TableHeadCell>
              <TableHeadCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">{t('input.fullName')}</TableCell>
              <TableCell align="left">{`${user.first_name} ${user.last_name}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">{t('input.email')}</TableCell>
              <TableCell align="left">{user.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">{t('feideId')}</TableCell>
              <TableCell align="left">{user.feide_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">{t('input.nationalIdNumber')}</TableCell>
              <TableCell align="left">{user.fnr}</TableCell>
            </TableRow>
            {enableNationalIdCardNumber && (
              <TableRow>
                <TableCell align="left">
                  {t('input.nationalIdCardNumber')}
                </TableCell>
                <TableCell align="left">
                  {user.national_id_card_number}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell align="left">{t('input.passportNumber')}</TableCell>
              <TableCell align="left">{user.passport}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">{t('input.mobilePhone')}</TableCell>
              <TableCell align="left">
                <form onSubmit={handleSubmit(submit)}>
                  <Controller
                    name="mobilePhone"
                    control={control}
                    rules={{
                      validate: (value) => isValidMobilePhoneNumber(value),
                    }}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="mobilePhone"
                        label={t('input.mobilePhone')}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={!!errors.mobilePhone}
                        helperText={errors?.mobilePhone?.message}
                      />
                    )}
                  />
                  <Box>
                    <Button
                      type="submit"
                      color="secondary"
                      sx={{
                        marginTop: '0.5rem',
                      }}
                      disabled={!isValid || !isDirty}
                    >
                      {t('button.save')}
                    </Button>
                  </Box>
                </form>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h2">{t('guestInfo.roleInfoHead')}</Typography>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: 'none' }}>
            <TableRow>
              <TableHeadCell align="left">{t('common:role')}</TableHeadCell>
              <TableHeadCell align="left">{t('common:period')}</TableHeadCell>
              <TableHeadCell align="left">{t('common:ou')}</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role) => (
              <UserRoleLine key={role.id} role={role} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h2">{t('guestInfo.consentInfoHead')}</Typography>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: 'none' }}>
            <TableRow>
              <TableHeadCell align="left">
                {t('guestInfo.consentName')}
              </TableHeadCell>
              <TableHeadCell align="left">
                {t('guestInfo.choiceDate')}
              </TableHeadCell>
              <TableHeadCell align="left">{t('common:choice')}</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consents.map((cons) => (
              <UserConsentLine key={cons.id} consent={cons} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  )
}

const AnonPage = () => {
  const [t] = useTranslation('common')
  return (
    <Page>
      <Typography variant="h2">{t('anonpage.top')}</Typography>
      <Typography variant="body1">{t('anonpage.body')}</Typography>
    </Page>
  )
}

function LandingPage() {
  const { user, getUserInfo } = useUserContext()

  if (user.sponsor_id) {
    return <Navigate to="/sponsor" />
  }
  if (user.person_id) {
    if (!user.registration_completed_date) {
      return <Navigate to="/guestregister" />
    }
    return <GuestPage getUserInfo={getUserInfo} />
  }
  return <AnonPage />
}

export default LandingPage

import { User } from 'interfaces'
import { createContext, useContext } from 'react'

interface IUserContext {
  user: User
  fetchUserInfo: () => void
  clearUserInfo: () => void
  getUserInfo: () => void
}

function noop() {}

export const UserContext = createContext<IUserContext>({
  user: {
    auth: false,
    auth_type: '',
    fetched: false,
    first_name: '',
    last_name: '',
    feide_id: '',
    person_id: '',
    sponsor_id: '',
    email: '',
    mobile_phone: '',
    fnr: '',
    national_id_card_number: '',
    passport: '',
    roles: [],
    consents: [],
    registration_completed_date: null,
  },
  fetchUserInfo: noop,
  clearUserInfo: noop,
  getUserInfo: noop,
})
export const useUserContext = () => useContext(UserContext)
